import Header from "../header/Header";
import "./Dashboard.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import DesktopMenu from "../menu/DesktopMenu";
import { CheckAdmin } from "../utils/CheckAdmin";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ReactGA from "react-ga4";
import {
  faHouse,
  faCalendar,
  faList,
  faUser,
  faClipboard,
  faClipboardList,
  faClipboardCheck,
  faFileClipboard,
  faBellConcierge,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faFileClipboard,
  faClipboardCheck,
  faClipboardList,
  faClipboard,
  faHouse,
  faCalendar,
  faList,
  faUser,
  faBellConcierge,
);

const Dashboard = () => {
  const [pausing, setPausing] = useState(true);
  const { t } = useTranslation();
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  setTimeout(function () {
    setPausing(false);
  }, 1500);
  const handleLinkClick = (label) => {
    ReactGA.event({
      category: "Dashboard page link",
      action: "Click",
      label: label, // Label to identify which link was clicked
    });
  };
  return (
    <div id="dashboard" className="main-container">
      <Header />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {pausing && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {!pausing && (
          <>
            <h1 className="sr-only">Dashboard</h1>
            {CheckAdmin() && (
              <div className="card" id="addjob-card">
                <h5 className="card-title">
                  {t("createJobCardheaderText1")}
                  <br />
                  {t("createJobCardheaderText2")}
                </h5>
                <div className="card-body">
                  <FontAwesomeIcon
                    className="icon"
                    size="3x"
                    icon="fa-solid fa-calendar-plus"
                  />
                  <Link
                    onClick={() => handleLinkClick("Add Job Link")}
                    to="/job/add"
                    className="btn btn-info"
                  >
                    {t("openButtonText")}
                  </Link>
                </div>
              </div>
            )}
            <div className="card" id="todayjobs-card">
              <h5 className="card-title">
                {t("todaysJobsCardHeaderText")}
                <br />
                {t("jobsCardHeaderText")}
              </h5>
              <div className="card-body">
                <FontAwesomeIcon
                  className="icon"
                  size="3x"
                  icon="clipboard-list"
                />
                <Link to="/jobs/today" className="btn btn-info">
                  {t("viewButtonText")}
                </Link>
              </div>
            </div>
            <div className="card" id="tomorowsjobs-card">
              <h5 className="card-title">
                {t("tomorrowsJobsCardHeaderText")}
                <br />
                {t("jobsCardHeaderText")}
              </h5>
              <div className="card-body">
                <FontAwesomeIcon
                  className="icon"
                  size="3x"
                  icon="file-clipboard"
                />
                <Link
                  onClick={() => handleLinkClick("View tomorrow jobs")}
                  to="/jobs/tomorrow"
                  className="btn btn-info"
                >
                  {t("viewButtonText")}
                </Link>
              </div>
            </div>
            <div className="card" id="feature-request-card">
              <h5 className="card-title">
                {t("featureRequestCardheaderText1")}
                <br />
                {t("featureRequestCardheaderText2")}
              </h5>
              <div className="card-body">
                <FontAwesomeIcon
                  className="icon"
                  size="3x"
                  icon="bell-concierge"
                />
                <Link
                  onClick={() => handleLinkClick("Feature Request Link")}
                  to="/feature-request"
                  className="btn btn-info"
                >
                  {t("openButtonText")}
                </Link>
              </div>
            </div>
            {!CheckAdmin() && (
              <div className="card" id="profile-card">
                <h5 className="card-title">
                  My
                  <br />
                  Details
                </h5>
                <div className="card-body">
                  <FontAwesomeIcon className="icon" size="3x" icon="user" />
                  <Link
                    onClick={() => handleLinkClick("View own profile Link")}
                    to="/user/profile"
                    className="btn btn-info"
                  >
                    Go
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
