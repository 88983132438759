import "./Staff.scss";
import { useState, useEffect } from "react";

import Unauthorised from "../notifs/Unauthorised";
import BackButton from "../elements/backbutton/Backbutton";
import Header from "../header/Header";
import DesktopMenu from "../menu/DesktopMenu";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

//import { useHistory } from "react-router";

const addStaff = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  //const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const name = firstName.trim() + " " + lastName.trim();
    const staff = { name, email, phone: mobile };
    fetch(api_url + "/manager/user/add", {
      method: "PUT",
      headers: api_headers,
      body: JSON.stringify(staff),
    }).then(() => {
      ReactGA.event({
        category: "User",
        action: "added staff member",
      });
      setSubmitting(false);
      setSubmitted(true);
      setFirstName("");
      setLastName("");
      setEmail("");
      setMobile("");
      //document.getElementById("add-user-form").reset();
      console.log("new staff added");
      // history.go(-1);
      //history.push("/staff");
    });
  };

  useEffect(() => {
    fetch(api_url + "/user/profile", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        setBlockedStatus(data.message == "access_blocked");
      })
      .catch((error) => {
        ReactGA.event({
          category: "User",
          action: "Failed to add staff member",
          label: error,
        });
        console.log("error", error);
      });
  }, []);

  return (
    <div id="add-staff" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}

        {submitting && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {!submitting && !blockedStatus && (
          <>
            {submitted && (
              <div className="alert alert-success">
                We&apos;re setting up an account for your new user. It will be
                ready in approximately 10 minutes. They will receive an email
                when it&apos;s ready.
              </div>
            )}
            <div id="form-container">
              <h1>Add Staff Member</h1>

              <form onSubmit={handleSubmit} id="add-user-form">
                <div className="form-group">
                  <label htmlFor="firstName">
                    First Name<span>*</span>
                  </label>
                  <input
                    onChange={(e) => setFirstName(e.target.value)}
                    className="form-control"
                    name="firstName"
                    id="firstName"
                    placeholder="First name"
                    type="text"
                    defaultValue={firstName}
                    required
                  />{" "}
                </div>
                <div className="form-group">
                  <label htmlFor="lastName">
                    Last Name<span>*</span>
                  </label>
                  <input
                    onChange={(e) => setLastName(e.target.value)}
                    className="form-control"
                    name="lastName"
                    placeholder="Last name"
                    type="text"
                    defaultValue={lastName}
                    required
                  />{" "}
                </div>

                <div className="form-group">
                  <label htmlFor="mobile">
                    Cell/Mobile<span>*</span>
                  </label>
                  <input
                    onChange={(e) => setMobile(e.target.value)}
                    className="form-control"
                    name="mobile"
                    type="tel"
                    placeholder="Mobile number"
                    required
                    defaultValue={mobile}
                  />{" "}
                </div>

                <div className="form-group">
                  <label htmlFor="email">
                    Email<span>*</span>
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    name="email"
                    placeholder="Email address"
                    type="email"
                    defaultValue={email}
                    required
                  />{" "}
                </div>
                <button className="btn btn-primary" type="Submit">
                  Save
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default addStaff;
