export const FormatTime = (time = null) => {
  let today = time ? time : new Date();

  let hours =
    today.getHours() > 9 ? String(today.getHours()) : "0" + today.getHours();
  let minutes =
    today.getMinutes() > 9
      ? today.getMinutes()
      : "0" + today.getMinutes().toString();
  let seconds =
    today.getSeconds() > 9
      ? today.getSeconds()
      : "0" + today.getSeconds().toString();
  return hours + ":" + minutes + ":" + seconds;
};
