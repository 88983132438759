import "./Staff.scss";
import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import { useState, useEffect } from "react";
import { CheckAdmin } from "../utils/CheckAdmin";
import { ForceLogout } from "../utils/ForceLogout";
import BackButton from "../elements/backbutton/Backbutton";
import Header from "../header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import DesktopMenu from "../menu/DesktopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import {
  faEnvelope,
  faPhone,
  faComment,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faEye, faPhone, faEnvelope, faComment);
const isAdmin = CheckAdmin();

const ListStaff = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { t } = useTranslation();
  const [staffList, setStaffList] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);

  const token = Cookies.get("token");

  const cbuser = JSON.parse(sessionStorage.getItem("cb-user"))["user_id"];

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  const callHandler = (mobile) => {
    // Check if the input is a string and contains only digits
    if (typeof mobile !== "string" || !/^[\d+]+$/.test(mobile)) {
      console.error("Invalid phone number format.");
      return;
    }

    let sanitizedMobile = mobile;
    if (mobile.charAt(0) == "0") {
      mobile = "+44" + mobile.slice(1);
    }
    // Use encodeURIComponent to ensure the phone number is properly escaped
    const telLink = `tel:${encodeURIComponent(sanitizedMobile)}`;

    // Set the location.href to the sanitized tel link
    window.location.href = telLink;
  };

  useEffect(() => {
    setFetchingData(true);
    fetch(api_url + "/manager/list/users", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        setTimeout(() => {
          setFetchingData(false);

          setBlockedStatus(data.message == "access_blocked");
          setStaffList(data.users);
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div id="list-staff" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {!isAdmin && (
          <div className="standard-container alert">
            <div className="alert alert-warning">{t("notFound")}</div>
          </div>
        )}

        {fetchingData && isAdmin && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}

        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}

        {!fetchingData && staffList && isAdmin && (
          <>
            <h1 className="sr-only">Your staff</h1>
            <p>
              You have {staffList.length} registered staff member
              {staffList.length > 1 ? "s" : ""}
            </p>
            <div id="staff-list-container">
              <table id="staff-list-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {staffList.map((user) => (
                    <tr key={user.UserId}>
                      <td>
                        {user.UserName} {user.UserId == cbuser && <>(you)</>}
                      </td>
                      <td>
                        {!user.UserMobile ? (
                          <>
                            <Link
                              style={{
                                display: "inline-block",
                                marginLeft: "44%",
                              }}
                              to={"/user/profile"}
                              className="btn btn-primary btn-sm"
                            >
                              <FontAwesomeIcon size="2x" icon="eye" />
                              <span className="sr-only">View</span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              className="btn btn-primary btn-sm"
                              to="#"
                              onClick={() => {
                                callHandler(user.UserMobile);
                              }}
                            >
                              <FontAwesomeIcon size="2x" icon="phone" />
                              <span className="sr-only">Call</span>
                            </Link>
                            |
                            <Link
                              to={`/staff/view/${user.UserId}`}
                              className="btn btn-primary btn-sm"
                            >
                              <FontAwesomeIcon size="2x" icon="eye" />
                              <span className="sr-only">View</span>
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Link
              id="add-staff-btn"
              className="btn btn-secondary"
              to="/staff/add"
            >
              Add staff member
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ListStaff;
