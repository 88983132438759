import "./FeatureRequest.scss";
//import Unauthorised from "../notifs/Unauthorised";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useState } from "react";
import Header from "../header/Header";
import BackButton from "../elements/backbutton/Backbutton";
import Elipses from "../helpers/Elipses";
import DesktopMenu from "../menu/DesktopMenu";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

const FeatureRequest = () => {
  const [sendingData, setSendingData] = useState(false);
  //const [blockedStatus, setBlockedStatus] = useState(false);
  //const [pausing, setPausing] = useState(false);
  const [featureTitle, setFeatureTitle] = useState("");
  const [featureDescription, setFeatureDescription] = useState("");
  const [featureRequestError, setFeatureRequestError] = useState(false);
  const [featureRequestSuccess, setFeatureRequestSuccess] = useState(false);
  const { t } = useTranslation();

  const token = Cookies.get("token");

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  const featureSubmit = (e) => {
    e.preventDefault();
    setFeatureRequestError(false);
    setFeatureRequestSuccess(false);

    setTimeout(function () {
      setSendingData(true);
    }, 1000);

    let payload = {
      name: featureTitle.replace(/[^a-zA-Z0-9 ,.-_]/g, ""),
      description: featureDescription.replace(/[^a-zA-Z0-9 ,.-_]/g, ""),
    };
    setSendingData(true);
    fetch(api_url + "/feature-request/send", {
      method: "POST",
      headers: api_headers,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        setFeatureRequestError(false);
        if (response.ok) {
          setTimeout(function () {
            setSendingData(false);
            setFeatureRequestSuccess(true);
            setFeatureTitle("");
            setFeatureDescription("");
          }, 500);
        } else {
          //setTimeout(function () {
          setSendingData(false);
          //}, 500);
          throw new Error(response.status);
        }
      })
      .catch((error) => {
        console.log(error);
        setSendingData(false);

        setTimeout(function () {
          setSendingData(false);
          setFeatureRequestError(true);
        }, 500);
      });
    console.log("submitting feature request" + JSON.stringify(payload));
  };
  return (
    <div id="feature-request" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        <h1 className="sr-only">
          {t("featureRequestCardheaderText1")}
          {t("featureRequestCardheaderText2")}
        </h1>
        <div id="feature-request-container">
          <h2>
            {t("featureRequestCardheaderText1")}
            {t("featureRequestCardheaderText2")}
          </h2>
          <div>
            {sendingData && <Elipses />}
            {featureRequestError && (
              <div className="alert alert-danger" role="alert">
                {t("featureRequestSubmitFailed")}
              </div>
            )}
            {featureRequestSuccess && (
              <div className="alert alert-success" role="alert">
                {t("featureRequestSubmitSuccess")}
              </div>
            )}
          </div>
          <form onSubmit={featureSubmit} className="feature-request-form">
            <div className="form-group">
              <label className="sr-only">Title</label>
              <input
                type="text"
                className="form-control"
                placeholder={t("featureRequestTitlePlaceholder")}
                required
                value={featureTitle}
                onChange={(e) => setFeatureTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label className="sr-only">Description</label>
              <textarea
                className="form-control"
                placeholder={t("featureRequestDescriptionPlaceholder")}
                required
                maxLength="200"
                value={featureDescription}
                onChange={(e) => setFeatureDescription(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              {t("featureRequestSubmitButtonText")}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeatureRequest;
