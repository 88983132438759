import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "react-sliding-pane/dist/react-sliding-pane.css";
import SignIn from "./components/auth/Login";
import ReactGA from "react-ga4";

import {
  faCheckSquare,
  faThLarge,
  faCoffee,
  faFolderPlus,
  faListAlt,
  faPlus,
  faUser,
  faUsers,
  faSignOutAlt,
  faUserPlus,
  faTasks,
  faAddressBook,
  faAddressCard,
  faCalendarAlt,
  faBars,
  faTimes,
  faClipboard,
  faPersonCirclePlus,
  faCalendarDay,
  faCalendarWeek,
  faCalendarDays,
  faCalendarPlus,
  faPeopleGroup,
  faLock,
  faLockOpen,
  faPhone,
  faMessage,
  faMobile,
} from "@fortawesome/free-solid-svg-icons";
//import { Routes, Route, Navigate } from "react-router-dom";
// import logo from "./logo.svg";
import Cookies from "js-cookie";
import Dashboard from "./components/dashboard/Dashboard";
import ListStaff from "./components/staff/List";
import ListJobsToday from "./components/jobs/ListToday";
import ListJobsTomorrow from "./components/jobs/ListTomorrow";
import Addjob from "./components/jobs/Add";
import Addstaff from "./components/staff/Add";
import StaffDetails from "./components/staff/StaffDetails";
import Listclients from "./components/clients/List";
import ClientDetails from "./components/clients/ClientDetails";
import Addclient from "./components/clients/Add";
import Uploadclients from "./components/clients/Upload";
import Profile from "./components/user/Profile";
import FullCalendar from "./components/calendar/Calendar";
import Company from "./components/company/Company";
import JobDetails from "./components/jobs/JobDetails";
import JobDetailsAdmin from "./components/jobs/JobDetailsAdmin";
import TeamListJobsToday from "./components/jobs/TeamListToday";
import TeamListJobsWeek from "./components/jobs/TeamListWeek";
import TeamListJobsMonth from "./components/jobs/TeamListMonth";
import FeatureRequest from "./components/featureRequest/FeatureRequest";
import "bootstrap/dist/css/bootstrap.min.css";

library.add(
  fab,
  faClipboard,
  faSignOutAlt,
  faThLarge,
  faAddressCard,
  faAddressBook,
  faTasks,
  faFolderPlus,
  faCheckSquare,
  faCoffee,
  faPlus,
  faListAlt,
  faUserPlus,
  faUser,
  faUsers,
  faBars,
  faCalendarAlt,
  faTimes,
  faClipboard,
  faPersonCirclePlus,
  faCalendarDay,
  faCalendarWeek,
  faCalendarDays,
  faCalendarPlus,
  faPeopleGroup,
  faLock,
  faLockOpen,
  faPhone,
  faMessage,
  faMobile,
);

const App = () => {
  Cookies.get("token");
  ReactGA.initialize("G-9H85HK2WSC");
  if (!Cookies.get("token")) {
    return <SignIn />;
  } else {
    return (
      <div>
        {/* <FontAwesomeIcon size="2x" icon={["fas", "bars"]} /> */}

        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} exact />
            <Route path="/dashboard" element={<Dashboard />} exact />
            <Route path="/calendar" element={<FullCalendar />} exact />
            <Route path="/staff" element={<ListStaff />} exact />
            <Route path="/staff/add" element={<Addstaff />} />
            <Route path="/staff/view/:sub" element={<StaffDetails />} />
            <Route path="/jobs/today" element={<ListJobsToday />} exact />
            <Route path="/jobs/tomorrow" element={<ListJobsTomorrow />} exact />
            <Route path="/job/add" element={<Addjob />} />
            <Route path="/job/view/:JobId" element={<JobDetails />} />
            <Route
              path="/job/admin/view/:JobId"
              element={<JobDetailsAdmin />}
            />
            <Route
              path="/team/jobs/today"
              element={<TeamListJobsToday />}
              exact
            />
            <Route
              path="/team/jobs/week"
              element={<TeamListJobsWeek />}
              exact
            />
            <Route
              path="/team/jobs/month"
              element={<TeamListJobsMonth />}
              exact
            />
            <Route path="/clients" element={<Listclients />} exact />
            <Route path="/client/:sub" element={<ClientDetails exact />} />
            <Route path="/client/add" element={<Addclient exact />} />
            <Route path="/clients/upload" element={<Uploadclients />} />
            <Route path="/user" element={<Profile />} exact />
            <Route path="/user/profile" element={<Profile />} />
            <Route path="/company/profile" element={<Company />} />
            <Route path="/feature-request" element={<FeatureRequest />} />
          </Routes>
        </Router>
      </div>
    );
  }
};

export default App;
