import "./Clients.scss";
import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import { CheckAdmin } from "../utils/CheckAdmin";
import { useState, useEffect } from "react";
import Header from "../header/Header";
import Cookies from "js-cookie";
import { ForceLogout } from "../utils/ForceLogout";
import BackButton from "../elements/backbutton/Backbutton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import DesktopMenu from "../menu/DesktopMenu";
import ReactGA from "react-ga4";
import {
  faEnvelope,
  faPhone,
  faComment,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faEye, faPhone, faEnvelope, faComment);

const isAdmin = CheckAdmin();

const ListClients = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { t } = useTranslation();
  const [clientsList, setClientsList] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);

  const token = Cookies.get("token");

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  const callHandler = (telephone) => {
    // Check if the input is a string and contains only digits
    if (typeof telephone !== "string" || !/^[\d+]+$/.test(telephone)) {
      console.error("Invalid phone number format.");
      return;
    }

    // Use encodeURIComponent to ensure the phone number is properly escaped
    const telLink = `tel:${encodeURIComponent(telephone)}`;

    // Set the location.href to the sanitized tel link
    window.location.href = telLink;
  };

  useEffect(() => {
    setFetchingData(true);
    fetch(api_url + "/customers", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        console.log(data);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        setTimeout(() => {
          setFetchingData(false);
          setBlockedStatus(data.message == "access_blocked");
          setClientsList(data.customers);
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div id="list-clients" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {!isAdmin && (
          <div className="standard-container alert">
            <div className="alert alert-warning">{t("notFound")}</div>
          </div>
        )}

        {fetchingData && isAdmin && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}

        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}

        {!fetchingData && clientsList && isAdmin && (
          <>
            <h1 className="sr-only">Your Clients</h1>
            <p>
              {t("youHave")} {clientsList.count} {t("registeredClients")}
            </p>
            <div id="clients-list-container">
              <table id="clients-list-table">
                <thead>
                  <tr>
                    <th>{t("clientName")}</th>
                    <th>{t("contactPerson")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {clientsList.items.map((client) => (
                    <tr key={client.CustomerId}>
                      <td>{client.CustomerName}</td>
                      <td>{client.CustomerContact}</td>
                      <td>
                        <Link
                          to="#"
                          onClick={() => {
                            callHandler(client.CustomerTel);
                          }}
                          className="btn btn-info btn-sm"
                        >
                          <FontAwesomeIcon size="2x" icon="phone" />
                          <span className="sr-only">Call</span>
                        </Link>
                        |
                        <Link
                          to={`/client/${client.CustomerId}`}
                          className="btn btn-primary btn-sm"
                        >
                          <FontAwesomeIcon size="2x" icon="eye" />
                          <span className="sr-only">View</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Link
              className="btn btn-secondary add-clients-btn "
              to="/client/add"
            >
              {t("addClient")}
            </Link>
            <Link
              className="btn btn-default add-clients-btn "
              to="/clients/upload"
            >
              {t("addMultipleClients")}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ListClients;
