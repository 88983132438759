import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoutButton from "../auth/Logoutbutton";
//import { CheckAdmin } from "../utils/CheckAdmin";

const SlideUpMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAdmin, SetIsAdmin] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const checkIfAdmin = () => {
      const user = JSON.parse(sessionStorage.getItem("cb-user"));
      if (
        user &&
        (user.role.toLowerCase() === "admin" ||
          user.role.toLowerCase() === "superadmin")
      ) {
        SetIsAdmin(true);
      }
    };
    checkIfAdmin();
  }, []);

  return (
    <div id="mobile-menu">
      <button
        type="button"
        id="menu-bars"
        className="btn btn-default"
        onClick={toggleMenu}
      >
        <FontAwesomeIcon size="2x" icon={["fas", "bars"]} />
      </button>
      <div className={`menu ${isOpen ? "open" : ""}`}>
        <nav>
          <ul>
            <li>
              <Link
                className="btn btn-secondary"
                to="/"
                onClick={() => setIsOpen(false)}
              >
                <span>Dashboard</span>
              </Link>
            </li>
          </ul>
          <ul className="menu-group">
            <li className="menu-title">Jobs</li>
            <li className="menu-item">
              <Link
                className="btn btn-secondary"
                to="/jobs/today"
                onClick={() => setIsOpen(false)}
              >
                <span> List my jobs</span>
              </Link>
            </li>
            {isAdmin && (
              <li className="menu-item">
                <Link
                  className="btn btn-secondary"
                  to="/job/add"
                  onClick={() => setIsOpen(false)}
                >
                  <span>Add job</span>
                </Link>
              </li>
            )}
          </ul>
          {isAdmin && (
            <>
              <ul className="menu-group">
                <li className="menu-title">Staff</li>
                <li className="menu-item">
                  <Link
                    className="btn btn-secondary"
                    to="/staff"
                    onClick={() => setIsOpen(false)}
                  >
                    <span> List staff</span>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    className="btn btn-secondary"
                    to="/staff/add"
                    onClick={() => setIsOpen(false)}
                  >
                    <span>Add staff</span>
                  </Link>
                </li>
              </ul>
              <ul className="menu-group">
                <li className="menu-title">Clients</li>
                <li className="menu-item">
                  <Link
                    className="btn btn-secondary"
                    to="/clients"
                    onClick={() => setIsOpen(false)}
                  >
                    <span> List clients</span>
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    className="btn btn-secondary"
                    to="/client/add"
                    onClick={() => setIsOpen(false)}
                  >
                    <span>Add client</span>
                  </Link>
                </li>
              </ul>
            </>
          )}

          <ul className="menu-group">
            <li className="menu-title">Me</li>
            <li className="menu-item">
              <Link
                className="btn btn-secondary"
                to="/user/profile"
                onClick={() => setIsOpen(false)}
              >
                <span> My profile</span>
              </Link>
            </li>
            <li className="menu-item">
              <LogoutButton />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SlideUpMenu;
