// Function to sanitize URL
const sanitizeUrl = (url) => {
  const pattern = /^[a-zA-Z0-9._-]+$/; // Allow only letters, numbers, dots, underscores, and hyphens
  return pattern.test(url) ? url : "";
};

// Function to escape HTML entities in strings
const escapeHtml = (unsafe) => {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

const ProfileImage = ({ profileData, refreshingImg }) => {
  const sanitizedImage = profileData.image
    ? sanitizeUrl(profileData.image)
    : "";
  const sanitizedAlt = escapeHtml(profileData.name);

  const imageUrl = sanitizedImage
    ? `https://profile-images.dev.cleanerbility.com/${sanitizedImage}?t=${new Date().getTime()}`
    : "/profile-image-placeholder.jpeg";

  return (
    <img
      id="profile-img"
      className={!refreshingImg ? "card-img-top" : "card-img-top faded"}
      src={imageUrl}
      alt={sanitizedAlt}
    />
  );
};

export default ProfileImage;
