import "./Jobs.scss";
import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useState, useEffect } from "react";
import Header from "../header/Header";
import BackButton from "../elements/backbutton/Backbutton";
import { ForceLogout } from "../utils/ForceLogout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FormatDate } from "../utils/DateFormatter";
import DesktopMenu from "../menu/DesktopMenu";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faCheck);

const ListJobsToday = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [jobsList, setJobsList] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);
  const { t } = useTranslation();

  const token = Cookies.get("token");
  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  useEffect(() => {
    setFetchingData(true);
    fetch(api_url + "/jobs/list/today", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        //console.log(data);
        setTimeout(() => {
          setFetchingData(false);

          setBlockedStatus(data.message == "access_blocked");
          setJobsList(data.jobs);
          //console.log(jobsList);
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div id="list-jobs-today" className="main-container list-jobs">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        {!fetchingData && jobsList && (
          <>
            <h1>
              {t("todaysJobsCardHeaderText")} {t("jobsCardHeaderText")} (
              {FormatDate()})
            </h1>
            <div className="standard-container" id="jobs-list-container">
              <table id="jobs-list-table">
                <thead>
                  <tr>
                    <th>{t("jobsListClientName")}</th>
                    <th>{t("jobsListJobTime")}</th>
                    <th>{t("jobsListJobCompleted")}</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsList.count < 1 && (
                    <tr style={{ background: "#fff" }}>
                      <td colSpan={3}>{t("jobsListNoneToday")}</td>
                    </tr>
                  )}

                  {jobsList.count > 0 && (
                    <>
                      {jobsList.items.map((job) => (
                        <tr
                          key={job.JobId}
                          className={job.Checkin && job.Checkout && "job-done"}
                        >
                          <td>
                            <Link
                              className="btn btn-default"
                              to={`/job/view/${job.JobId}`}
                            >
                              {job.customer.CustomerName}
                            </Link>
                          </td>
                          <td>{job.JobTime}</td>
                          <td className="action">
                            {job.Checkin && job.Checkout && (
                              <FontAwesomeIcon
                                className="icon"
                                size="2x"
                                icon="check"
                              />
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListJobsToday;
