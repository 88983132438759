import "./Jobs.scss";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import DesktopMenu from "../menu/DesktopMenu";
//import Elipses from "../helpers/Elipses";
import { ForceLogout } from "../utils/ForceLogout";
import BackButton from "../elements/backbutton/Backbutton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";

//import { FormatTime } from "../utils/TimeFormatter";
import ConfirmModal from "../utils/ModalConfirm";
import Header from "../header/Header";
import { FormatDate } from "../utils/DateFormatter";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faPhone, faEnvelope, faMapLocation);

const JobDetailsAdmin = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [jobInfo, setJobInfo] = useState({});
  const [accessBlocked, setAccessBlocked] = useState(null);
  const [fetchingData, setFetchingData] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [errorDeleteDisplay, setErrorDeleteDisplay] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkedOut, setCheckedOut] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [showModalDelete, setShowModalDelete] = useState(false);

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const job_id = location.href.split("/").pop();

  const handleConfirmDelete = () => {
    setShowModalDelete(true);
  };

  const handleCloseModal = () => {
    setShowModalDelete(false);
  };

  const handleDelete = () => {
    setShowModalDelete(false);
    doDelete();
  };

  const doDelete = () => {
    setErrorDeleteDisplay(false);

    fetch(api_url + "/manager/job/" + job_id, {
      headers: api_headers,
      method: "DELETE",
    })
      .then((response) => {
        const statusCode = response.status;

        statusCode === 204 && window.history.back(); // Navigate to the previous page if the status code is 204

        if (statusCode === 204) {
          window.location.href = "/team/jobs/week";
        }

        // Optional: Handle different status codes
        if (statusCode === 401) {
          ForceLogout();
        } else if (statusCode === 403) {
          setAccessBlocked(true);
        } else if (statusCode === 500) {
          setErrorDeleteDisplay(true);
        }

        return response.text();
      })
      .then((result) => {
        if (result) {
          const data = JSON.parse(result);

          if (data.message === "The incoming token has expired") {
            ForceLogout();
          }

          setAccessBlocked(data.message === "access_blocked");
          setErrorDeleteDisplay(data.message === "error");
        }
        setFetchingData(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchData = () => {
    fetch(api_url + "/manager/view/job/" + job_id, {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        const data = JSON.parse(result);
        console.log(data);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        setAccessBlocked(data.message == "access_blocked");
        setJobInfo(data);
        setFetchingData(false);

        if (data.message == "error") {
          setErrorDisplay(true);
        }

        setCustomerName(data.customer.CustomerName);
        setCheckedIn(data.Checkin);
        setCheckedOut(data.Checkout);
        setAssignees(data.JobAssignees);
        setTimeout(() => {
          setFetchingData(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingData(false);
        setErrorDisplay(true);
      });
  };

  useEffect(() => {
    setFetchingData(true);
    setErrorDisplay(false);

    fetchData();
  }, []);

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60); // Get the number of whole hours
    const remainingMinutes = minutes % 60; // Get the remaining minutes
    let resp = "";
    if (hours === 1) {
      resp += `${hours} hr`;
    }
    if (hours > 1) {
      resp += `${hours} hrs`;
    }
    if (remainingMinutes > 0) {
      resp += ` ${remainingMinutes} min`;
    }
    return resp;
  };

  return (
    token && (
      <div className="view-job main-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}

        {!fetchingData && !accessBlocked && (
          <div id="list-jobs-today" className="main-container list-jobs">
            <Header />
            <BackButton />
            <div className="desktop-menu-container">
              <DesktopMenu />
            </div>
            <div id="view-job" className="main-content-container">
              {accessBlocked && (
                <div id="unauthorised-container" className="alert-container">
                  <Unauthorised />
                </div>
              )}
              {errorDisplay && (
                <div className="alert alert-warning">
                  Unable to locate job details
                </div>
              )}
              {errorDeleteDisplay && (
                <div className="alert alert-warning">
                  Unable to delete that job, sorry!
                </div>
              )}
              <h1>Job Card{jobInfo.JobDeleted && " - CANCELLED"}</h1>

              {!errorDisplay && (
                <div className="card standard-container" id="jobcard">
                  <div className="card-section">
                    <b>Client:</b> {customerName}
                    <br />
                    <b>Date:</b>{" "}
                    {FormatDate(new Date(new Date(jobInfo.JobDate)))}
                    <br />
                    <b>Time:</b> {jobInfo.JobTime}
                    <br />
                    <b>Duration:</b>{" "}
                    {convertMinutesToHours(jobInfo.JobDuration)}
                  </div>
                  <div className="card-section">
                    <h2>Assignees:</h2>
                    <ul>
                      {assignees.map((worker) => (
                        <li key={worker.assignee_id}>{worker.assignee_name}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-section">
                    <h2>Location:</h2>
                    <div id="address-container">
                      <address>
                        {jobInfo.JobAddress}
                        <br />
                        {jobInfo.JobCity}
                        <br />
                        {jobInfo.JobPostcode}
                      </address>
                    </div>
                    <div id="maps-link-container">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(jobInfo.JobAddress)}+${encodeURIComponent(jobInfo.JobCity)}+${encodeURIComponent(jobInfo.JobPostcode)}`}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          size="3x"
                          icon="map-location"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="card-section">
                    <h2>Comments:</h2>
                    {jobInfo.JobComments}
                  </div>
                  <div className="card-section">
                    {jobInfo.Checkin && (
                      <>
                        Checked-in: {jobInfo.Checkin}
                        <br />
                      </>
                    )}
                    {jobInfo.Checkout && <>Checked-out: {jobInfo.Checkout}</>}

                    <div id="job-buttons-container">
                      {!checkedIn && <p>Not yet checked in</p>}
                      {checkedIn && !checkedOut && <p>Not yet checked out</p>}
                    </div>
                  </div>
                  <div id="action-buttons-container">
                    <button
                      style={{ display: "none" }}
                      type="button"
                      className="btn btn-warning"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                      <span className="sr-only">Edit</span>
                    </button>
                    <button
                      style={{ float: "right" }}
                      onClick={handleConfirmDelete}
                      type="button"
                      className="btn btn-light"
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                      <span className="sr-only">Delete</span>
                    </button>
                    <ConfirmModal
                      show={showModalDelete}
                      handleClose={handleCloseModal}
                      handleConfirm={handleDelete}
                      title="Deleting this?"
                      body="Are you sure you want to delete this job?"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default JobDetailsAdmin;
