import "./Jobs.scss";
import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useState, useEffect } from "react";
import { ForceLogout } from "../utils/ForceLogout";
import Header from "../header/Header";
import BackButton from "../elements/backbutton/Backbutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { CheckAdmin } from "../utils/CheckAdmin";
import { useTranslation } from "react-i18next";
import DesktopMenu from "../menu/DesktopMenu";
import { FormatDate } from "../utils/DateFormatter";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faCheck);

const TeamListJobsWeek = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { t } = useTranslation();
  const [jobsList, setJobsList] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);
  const [apiError, setApiError] = useState(false);
  const isAdmin = CheckAdmin();

  const token = Cookies.get("token");
  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  useEffect(() => {
    setFetchingData(true);
    fetch(api_url + "/manager/jobs/list/week", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "The incoming token has expired") {
          ForceLogout();
        }
        setApiError(data.message === "error");
        setTimeout(() => {
          setFetchingData(false);
          setBlockedStatus(data.message === "access_blocked");
          setJobsList(data);
          //console.log("Jobs list:", data);
        }, 1500);
      })
      .catch((error) => {
        console.log("Error fetching data:", error);
        setFetchingData(false);
        setApiError(true);
      });
  }, []);

  return (
    <div id="list-jobs-today" className="main-container list-jobs">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        {!fetchingData && jobsList && !isAdmin && (
          <div className="standard-container alert">
            <div className="alert alert-warning">{t("notFound")}</div>
          </div>
        )}

        {!fetchingData && jobsList && isAdmin && (
          <>
            {apiError && (
              <div className="alert alert-warning" role="alert">
                Unable to fetch data. Please try again later.
              </div>
            )}
            {!apiError && (
              <>
                <h1>All jobs for this week</h1>
                <div className="standard-container" id="jobs-list-container">
                  <table id="jobs-list-table">
                    <thead>
                      <tr>
                        <th>Client Name</th>
                        <th className="short-col">Date</th>
                        <th>Checked-in</th>
                        <th>Checked-out</th>
                        <th>Complete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobsList.jobs && jobsList.jobs.count < 1 && (
                        <tr>
                          <td colSpan={3}>
                            Your team doesn&apos;t have any listed this week.
                          </td>
                        </tr>
                      )}
                      {jobsList.jobs &&
                        jobsList.jobs.count > 0 &&
                        jobsList.jobs.items && (
                          <>
                            {jobsList.jobs.items.map((job) => (
                              <tr
                                key={job.JobId}
                                className={
                                  job.Checkin && job.Checkout && "job-done"
                                }
                              >
                                <td>
                                  <Link
                                    className="btn btn-default"
                                    to={`/job/admin/view/${job.JobId}`}
                                  >
                                    {job.Customer.CustomerName}
                                  </Link>
                                </td>
                                <td>{FormatDate(job.JobDate)}</td>
                                <td>{job.Checkin}</td>
                                <td>{job.Checkout}</td>
                                <td>
                                  {job.Checkin && job.Checkout && (
                                    <FontAwesomeIcon
                                      className="icon"
                                      size="2x"
                                      icon="check"
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TeamListJobsWeek;
