import UserPool from "../utils/UserPool";
import Cookies from "js-cookie";

const LogoutButton = () => {
  const logout = () => {
    const user = UserPool.getCurrentUser();
    user.signOut({ global: true });
    Cookies.remove("token");
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <a
      href="#"
      id="logout-btn"
      className="btn btn-secondary"
      onClick={() => logout()}
    >
      Log Out
    </a>
  );
};

export default LogoutButton;
