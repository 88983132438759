import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import icon from "../../images/cleanerbility-icon-trans.png";
import UserPool from "../utils/UserPool";
import { useTranslation } from "react-i18next";
import { CheckAdmin } from "../utils/CheckAdmin";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";

import "./DesktopMenu.scss";

const DesktopMenu = () => {
  const user = UserPool.getCurrentUser();
  const { t } = useTranslation();

  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  // const headerClick = () => {
  //   window.location.href = "/";
  // };

  const logout = () => {
    ReactGA.event({
      category: "Link",
      action: "Click",
      label: "Desktop logout link",
    });
    user.signOut({ global: true });
    Cookies.remove("token");
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <div id="desktop-menu">
      <div id="logo-container">
        <Link to="/">
          <img src={icon} alt="Cleanerbility Logo" />
        </Link>
      </div>
      <nav>
        <ul>
          <li>
            <h2>{t("myJobsMenuLabelText")}</h2>
          </li>
          <li>
            <Link to="/jobs/today">
              <FontAwesomeIcon icon="clipboard-list" />
              {t("todaysJobsMenuItemText")}
            </Link>
          </li>
          <li>
            <Link to="/jobs/tomorrow">
              <FontAwesomeIcon icon="file-clipboard" />
              {t("tomorrowsJobsMenuItemText")}
            </Link>
          </li>
        </ul>
        {CheckAdmin() && (
          <>
            <ul>
              <li>
                <h2>{t("teamJobsMenuLabelText")}</h2>
              </li>

              <li>
                <Link to="/team/jobs/today">
                  <FontAwesomeIcon icon="fa-solid fa-calendar-day" />
                  {t("teamJobsTodayMenuItemText")}
                </Link>
              </li>
              <li>
                <Link to="/team/jobs/week">
                  <FontAwesomeIcon icon="fa-solid fa-calendar-week" />
                  {t("teamJobsWeekMenuItemText")}
                </Link>
              </li>
              <li>
                <Link to="/calendar">
                  <FontAwesomeIcon icon="fa-solid fa-calendar" />
                  {t("teamJobsCalendarMenuItemText")}
                </Link>
              </li>
              <li>
                <Link to="/job/add">
                  <FontAwesomeIcon icon="fa-solid fa-calendar-plus" />
                  {t("teamJobsCreateMenuItemText")}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <h2>{t("staffMenuLabelText")}</h2>
              </li>
              <li>
                <Link to="/staff">
                  <FontAwesomeIcon icon="fa-solid fa-users" />
                  {t("listStaffMenuItemText")}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <h2>{t("clientsMenuLabelText")}</h2>
              </li>
              <li>
                <Link to="/clients">
                  <FontAwesomeIcon icon="fa-solid fa-people-group" />
                  {t("listClientsMenuItemText")}
                </Link>
              </li>
            </ul>
          </>
        )}
        <ul>
          <li>
            <h2>{t("actionsMenuLabelText")}</h2>
          </li>
          <li>
            <Link to="/feature-request">
              <FontAwesomeIcon icon="bell-concierge" />
              {t("featureRequestMenuItemText")}
            </Link>
          </li>{" "}
        </ul>
      </nav>
      <ul id="lower-nav">
        <li>
          <Link to="/user/profile">
            <FontAwesomeIcon icon="user" />
            {t("mydetailsMenuItemText")}
          </Link>
        </li>
        <li>
          <a href="#" onClick={() => logout()}>
            <FontAwesomeIcon icon="sign-out-alt" />
            {t("logoutMenuItemText")}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DesktopMenu;
