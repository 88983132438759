import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Elipses from "../helpers/Elipses";
import Unauthorised from "../notifs/Unauthorised";
import Header from "../header/Header";
import axios from "axios";
import MakePhoneNumberReadable from "../utils/NornaliseTelephoneNumber";
import BackButton from "../elements/backbutton/Backbutton";
import "./Profile.scss";
import ProfileImage from "../utils/ProfileImage";
import UserDetails from "../utils/GetUserDetails";
import { ForceLogout } from "../utils/ForceLogout";
import { sortProfileData } from "../utils/SortProfileData";
import DesktopMenu from "../menu/DesktopMenu";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
import {
  faPhone,
  faCheckSquare,
  faThLarge,
  faCoffee,
  faFolderPlus,
  faListAlt,
  faPlus,
  faUser,
  faSignOutAlt,
  faUserPlus,
  faTasks,
  faAddressBook,
  faAddressCard,
  faCalendarAlt,
  faBars,
  faTimes,
  //faEnvelope,
  faSquareCheck,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";

import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(
  fab,
  faPhone,
  faSignOutAlt,
  faThLarge,
  faAddressCard,
  faAddressBook,
  faTasks,
  faFolderPlus,
  faCheckSquare,
  faCoffee,
  faPlus,
  faListAlt,
  faUserPlus,
  faUser,
  faBars,
  faCalendarAlt,
  faTimes,
  faSquareCheck,
  faRefresh,
);

const Profile = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [userMobile, setUserMobile] = useState(null);
  const [userName, setUserName] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [fetchingData, setFetchingData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showImageUpload, setShowImageUpload] = useState(true);
  const [formTargetData, setFormTargetData] = useState(null);
  const [fetchingUrl, setFetchingUrl] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(null);
  const [fileSentError, setFileSentError] = useState(false);
  const [fileSent, setFileSent] = useState(false);
  const [fileSentSuccess, setFileSentSuccess] = useState(null);
  const [sendingFile, setSendingFile] = useState(false);
  const [fileGood, setFileGood] = useState(false);
  const [file, setFile] = useState(null);
  const [refreshingImg, setRefreshingImg] = useState(false);
  const [newEmail, setNewEmail] = useState(null);
  const [newPhone, setNewPhone] = useState(null);
  const [newUserName, setNewUserName] = useState(null);
  const [nameChanged, setNameChanged] = useState(false);
  const [smsOptedIn, setSmsOptedIn] = useState(false);
  const [emailOptedIn, setEmailOptedIn] = useState(false);

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const doPicFormReset = () => {
    setFileName(null);
  };

  const toggleImageUpdateBtns = () => {
    if (showImageUpload) {
      setShowImageUpload(false);
    } else {
      //setUpdateName(false);
      //setUpdateMobile(false);
    }
  };

  const toggleEditForm = () => {
    setNameChanged(false);
    setShowEditForm(showEditForm ? false : true);
  };

  const doCheckFile = (event) => {
    event.preventDefault;
    setFile(event.target.files[0]);
    setFileName(event.target.files[0]);
    setFileGood(true);
    getFormTargetData2(event.target.files[0]);
  };

  const doPicUpload = async (event) => {
    event.preventDefault();
    setSendingFile(true);
    setRefreshingImg(true);
    //if await is removed, console log will be called before the uploadFile() is executed completely.
    //since the await is added, this will pause here then console log will be called
    let res = await uploadFile(file);
    console.log(res.status);
    setSendingFile(false);
    setFileSent(true);
    setFileSentSuccess(res.status === 204);
    setFileSentError(res.status !== 204);
    setTimeout(function () {
      document.getElementById("profile-img").src =
        "https://profile-images.dev.cleanerbility.com/" +
        profileData.image +
        "?t=" +
        new Date().getTime();
    }, 3000);
    setFileName(null);
    setFetchingData(false);
    setFile(false);
    setSendingFile(false);
    setTimeout(function () {
      setRefreshingImg(false);
    }, 3500);
  };

  const getFormTargetData2 = async (file) => {
    setFetchingUrl(true);
    api_headers["Cache-Control"] = "max-age=0";
    await fetch(
      api_url +
        "/url-fetch/image-upload-url" +
        "/" +
        file["name"].slice(file["name"].lastIndexOf(".") + 1).toLowerCase(),
      {
        method: "GET",
        headers: api_headers,
      },
    )
      .then((response) => response.text())
      .then((result) => {
        setFormTargetData(JSON.parse(result));
        setFetchingUrl(false);
      })
      .catch((error) => console.log("error", error));
  };

  const uploadFile = async (file) => {
    const formData = new FormData();

    formData.append("acl", formTargetData["fields"]["acl"]);
    formData.append("Content-Type", formTargetData["fields"]["Content-Type"]);
    formData.append("key", formTargetData["fields"]["key"]);
    formData.append(
      "X-Amz-Credential",
      formTargetData["fields"]["x-amz-credential"],
    );
    formData.append(
      "X-Amz-Algorithm",
      formTargetData["fields"]["x-amz-algorithm"],
    );
    formData.append(
      "X-Amz-Signature",
      formTargetData["fields"]["x-amz-signature"],
    );
    formData.append("Policy", formTargetData["fields"]["policy"]);
    formData.append("X-Amz-Date", formTargetData["fields"]["x-amz-date"]);
    formData.append(
      "X-Amz-Security-Token",
      formTargetData["fields"]["x-amz-security-token"],
    );
    formData.append("file", file);

    return await axios.post(formTargetData.url, formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFetchingData(true);
    let payload = {};
    if (newEmail && newEmail !== profileData.email) {
      payload.email = newEmail;
    }
    if (newPhone && newPhone !== profileData.phone_number) {
      payload.phone_number = newPhone;
    }
    if (newUserName && newUserName !== userName) {
      payload.name = newUserName;
    }
    fetch(api_url + "/update/user/", {
      method: "PATCH",
      headers: api_headers,
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then(() => {
            setTimeout(() => {
              setUserName(newUserName);
              setUserMobile(newPhone);
              console.log(userMobile);
              setNameChanged(true);
              setShowEditForm(false);
              setFetchingData(false);
              UserDetails(Cookies.get("token"));
            }, 500);
          });
        } else if (response.status === 403) {
          setFetchingData(false);
          setBlockedStatus(response.message == "access_blocked");
        } else {
          throw new Error(response.status);
        }
      })

      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          setFetchingData(false);
        }, 500);
      });
  };

  useEffect(() => {
    setFetchingData(true);
    console.log(smsOptedIn);
    fetch(api_url + "/user/profile", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        console.log(data);

        if (data.message == "The incoming token has expired") {
          ReactGA.event({
            category: "User",
            action: "Forced logout",
            label: "user profile - token expired",
          });
          ForceLogout();
        }

        setTimeout(() => {
          setFetchingData(false);

          setBlockedStatus(data.message == "access_blocked");

          let pdata = sortProfileData(data.attributes);
          setProfileData(pdata);
          //console.log(pdata);
          setUserName(pdata.name);
          setEmailOptedIn(pdata["custom:optin_email"] === "yes");
          setSmsOptedIn(pdata["custom:optin_sms"] === "yes");
          setUserMobile(data.mobile);
        }, 500);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const doSmsOptIn = () => {
    // Use the callback version of setState to access the previous state
    setSmsOptedIn((prevSmsOptedIn) => {
      const newSmsOptedIn = !prevSmsOptedIn;
      let optinValue = newSmsOptedIn ? "yes" : "no";
      optinPreferencesUpdate("sms", optinValue);
    });
  };

  const doEmailOptIn = () => {
    // Use the callback version of setState to access the previous state
    setEmailOptedIn((prevEmailOptedIn) => {
      const newEmailOptedIn = !prevEmailOptedIn;
      let optinValue = newEmailOptedIn ? "yes" : "no";
      optinPreferencesUpdate("email", optinValue);
    });
  };

  const optinPreferencesUpdate = (type, value) => {
    ReactGA.event({
      category: "User",
      action: `${type.charAt(0).toUpperCase() + type.slice(1)} opt-${value === "yes" ? "in" : "out"}`,
    });

    let payload = { [type]: value };

    try {
      const response = fetch(`${api_url}/update/messaging/preferences`, {
        method: "PATCH",
        headers: api_headers,
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        if (response.status === 401) {
          ReactGA.event({
            category: "User",
            action: "Forced logout",
            label: "user profile - 401",
          });
          ForceLogout();
          return;
        }
        if (response.status === 403) {
          const data = response.json();
          setBlockedStatus(data.message === "access_blocked");
          return;
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = response.json();
      console.log(data);
    } catch (error) {
      console.error("Error updating preferences:", error);
    }
  };

  return (
    <div id="view-profile" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        <h1 className="hide">Your Details</h1>
        <br />
        {!fetchingData && profileData && !blockedStatus && (
          <div className="card" id="profilecard">
            <div id="profile-image-container">
              {refreshingImg && (
                <FontAwesomeIcon spin size="2x" icon={faRefresh} />
              )}
              <ProfileImage
                profileData={profileData}
                refreshingImg={refreshingImg}
              />

              {fileSentError && (
                <div className="alert alert-danger">
                  Apologies. I was unable to upload your file
                </div>
              )}
              {!showImageUpload && (
                <>
                  {!fetchingUrl && (
                    <button
                      id="btn-make-upload-available"
                      onClick={() => toggleImageUpdateBtns()}
                      className="btn btn-outline-info"
                    >
                      Update image
                    </button>
                  )}
                  {(fetchingUrl || sendingFile) && (
                    <div className="elipses-container">
                      <Elipses />
                    </div>
                  )}
                </>
              )}
              {showImageUpload && (
                <form
                  onSubmit={doPicUpload}
                  method="post"
                  encType="multipart/form-data"
                  id="profile-image-upload-form"
                >
                  <div className="profile-image-upload-container">
                    {!fileName && (
                      <>
                        <label
                          id="update-profile-image"
                          className="btn btn-info"
                          htmlFor="profile-image-upload"
                          disabled={!fileGood}
                        >
                          Choose
                        </label>
                        {!profileData.image && (
                          <button
                            className="btn btn-primary"
                            id="img-remove-btn"
                          >
                            {" "}
                            Remove{" "}
                          </button>
                        )}
                      </>
                    )}
                    {/* {(fetchingData || sendingFile) &&
                      !fileSent &&
                      !fetchingUrl && <Elipses />} */}
                    {!fetchingData && !fileSent && !sendingFile && (
                      <input
                        hidden
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        className="custom-file-input"
                        id="profile-image-upload"
                        onChange={doCheckFile}
                        defaultValue={fileName}
                      />
                    )}
                    <span id="file-chosen">
                      {!fileSentSuccess &&
                        fileName &&
                        `Chosen: ${fileName["name"]}`}
                    </span>
                    {fileName &&
                      fileSent &&
                      !fileSentSuccess &&
                      !fileSentError && <Elipses />}
                    {fileName && !fileSentSuccess && formTargetData && (
                      <div className="btns">
                        <button type="submit" className="btn btn-primary">
                          Save
                        </button>
                        <button
                          onClick={() => doPicFormReset()}
                          className="btn btn-default btn-small"
                          type="button"
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </form>
              )}
            </div>

            <div className="card-body">
              {showEditForm && (
                <form id="profile-edit-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      className="form-control"
                      type="text"
                      required
                      name="username"
                      defaultValue={userName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      className="form-control"
                      type="tel"
                      required
                      name="phone"
                      defaultValue={profileData.phone_number}
                      onChange={(e) => setNewPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="email"
                      required
                      name="email"
                      defaultValue={profileData.email}
                      onChange={(e) => setNewEmail(e.target.value)}
                    />
                  </div>
                  <div className="actions">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>

                    <button
                      className="btn btn-default"
                      onClick={toggleEditForm}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              )}
              {!showEditForm && !fetchingData && (
                <>
                  <h1 className="sr-only">Your details</h1>
                  {nameChanged && (
                    <alert className="alert alert-warning">
                      Your new name will show on next page load.
                    </alert>
                  )}
                  <h2 id="name-container">{userName}</h2>
                  <FontAwesomeIcon size="7x" icon={faUser} />
                  <ul>
                    <li>Email: {profileData.email}</li>
                    <li>
                      Phone: {MakePhoneNumberReadable(profileData.phone_number)}
                    </li>
                    <li>Role: {profileData.role}</li>
                  </ul>

                  <div className="toggle-switch-container">
                    Email Opt-in{" "}
                    <div className="toggle-switch">
                      <input
                        onChange={doEmailOptIn}
                        checked={emailOptedIn}
                        type="checkbox"
                        className="checkbox"
                        name="EmailOptin"
                        id="EmailOptin"
                        value={emailOptedIn !== undefined ? emailOptedIn : "no"}
                      />
                      <label className="label" htmlFor="EmailOptin">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>

                  <div className="toggle-switch-container">
                    SMS Opt-in{" "}
                    <div className="toggle-switch">
                      <input
                        onChange={doSmsOptIn}
                        checked={smsOptedIn}
                        type="checkbox"
                        className="checkbox"
                        name="SMSOptin"
                        id="SMSOptin"
                        value={smsOptedIn !== undefined ? smsOptedIn : "no"}
                      />
                      <label className="label" htmlFor="SMSOptin">
                        <span className="inner" />
                        <span className="switch" />
                      </label>
                    </div>
                  </div>

                  <button
                    onClick={toggleEditForm}
                    className="btn btn-secondary"
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
