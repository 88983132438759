import Cookies from "js-cookie";
import UserPool from "./UserPool";

export const ForceLogout = () => {
  const user = UserPool.getCurrentUser();
  user.signOut({ global: true });
  Cookies.remove("token");
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = "/";
  return;
};
