export const FormatDate = (dateString, showDay = true) => {
  const date = dateString ? new Date(dateString) : new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayx = date.getDate();
  const day = String(dayx).padStart(2, "0");
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  return showDay ? `${day} ${month} ${year}` : `${month} ${year}`;
};
