const Unauthorised = () => {
  return (
    <div className="alert alert-warning">
      <h1>Unauthorised</h1>
      <p>You have been blocked.</p>
      <p>Please contact your account owner.</p>
    </div>
  );
};

export default Unauthorised;
