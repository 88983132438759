const MakePhoneNumberReadable = (phone) => {
  if (!phone) {
    return;
  }

  const countryCode = phone.slice(0, 3);

  switch (countryCode) {
    case "+44": // UK numbers
      return "0" + phone.slice(3);
    case "+45": // DK numbers
      return phone.slice(3);
    case "+31": // NL numbers
      return phone.slice(3);
    case "+91": // IN numbers
      return phone.slice(3);
    default:
      return phone;
  }
};

export default MakePhoneNumberReadable;
