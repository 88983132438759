import { Calendar, momentLocalizer } from "react-big-calendar";
import { useState, useEffect } from "react";
import moment from "moment";
import Header from "../header/Header";
import DesktopMenu from "../menu/DesktopMenu";
import { ForceLogout } from "../utils/ForceLogout";
import Cookies from "js-cookie";
import "./Calendar.scss";
import ReactGA from "react-ga4";
import { getApiConfig } from "../../apiConfig";

const apiConfig = getApiConfig();
const localizer = momentLocalizer(moment);

const FullCalendar = () => {
  //const [jobsList, setJobsList] = useState(false);
  const [events, setEvents] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const token = Cookies.get("token");
  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  useEffect(() => {
    setFetchingData(true);
    GetJobs("month");
    setTimeout(() => {
      setFetchingData(false);
      //setBlockedStatus(data.message == "access_blocked");
    }, 1000);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const GetJobs = (month = new Date().toISOString().slice(0, 7)) => {
    let firstDay = formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(month), 1),
    );
    let lastDay = formatDate(
      new Date(new Date().getFullYear(), new Date().getMonth(month) + 1, 0),
    );

    fetch(api_url + `/manager/jobs/list/${firstDay}/${lastDay}`, {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        let elist = [];
        data.forEach((job) => {
          let newJob = {
            id: job["id"],
            title: job["title"],
            start: new Date(job["start"]),
            end: new Date(job["end"]),
          };
          elist.push(newJob);
        });

        setEvents(elist);
      })
      .catch((error) => {
        console.log("error", error);
        setEvents([]);
      });
    return false;
  };

  return (
    <div id="calendar" className="main-container">
      <Header />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        <h1>Calendar</h1>

        {fetchingData && !events && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}

        {!fetchingData && events && (
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%" }}
          />
        )}
      </div>
    </div>
  );
};

export default FullCalendar;
