import "./instrument";
import "./index.scss";
import "./i18n.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
//const { REACT_APP_ENVIRONMENT } = process.env;

// console.log("Environ:" + process.env.REACT_APP_ENVIRONMENT);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
