//import { sortProfileData } from "./SortProfileData";
import { getApiConfig } from "../../apiConfig";
import { ForceLogout } from "../utils/ForceLogout";
import Cookies from "js-cookie";

import ReactGA from "react-ga4";

const apiConfig = getApiConfig();

const UserDetails = (apiToken = null) => {
  let details;
  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let token = apiToken ? apiToken : Cookies.get("token");

  if (!Cookies.get("token")) {
    ReactGA.event({
      category: "User",
      action: "Forced logout",
      label: "user details - token missing",
    });
    ForceLogout();
  }

  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  fetch(api_url + "/user/profile/lite", {
    headers: api_headers,
    method: "GET",
  })
    .then((response) => response.text())
    .then((result) => {
      if (JSON.parse(result).message == "The incoming token has expired") {
        ReactGA.event({
          category: "User",
          action: "Forced logout",
          label: "user details - token expired",
        });
        ForceLogout();
      } else {
        details = JSON.parse(result);
        //console.log("Got user details: ", details);
        sessionStorage.setItem("cb-user", JSON.stringify(details));
      }
    });
  return details;
};

export default UserDetails;
