import { useNavigate } from "react-router-dom";
import "./Backbutton.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapLocation,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
library.add(faPhone, faEnvelope, faMapLocation, faCaretLeft);

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      id="back-btn"
      hidden
      className="btn btn-small btn-secondary"
      onClick={() => navigate(-1)}
    >
      <FontAwesomeIcon className="icon" icon="caret-left" />{" "}
      <span>Go back</span>
    </button>
  );
};

export default BackButton;
