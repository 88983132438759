import "./Jobs.scss";
//import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useState, useEffect } from "react";
import { ForceLogout } from "../utils/ForceLogout";
import Header from "../header/Header";
import BackButton from "../elements/backbutton/Backbutton";
import { FormatDate } from "../utils/DateFormatter";
import DesktopMenu from "../menu/DesktopMenu";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

const ListJobsTomorrow = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [jobsList, setJobsList] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);

  const token = Cookies.get("token");
  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  useEffect(() => {
    setFetchingData(true);
    fetch(api_url + "/jobs/list/tomorrow", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);

        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        //console.log(data);

        setTimeout(() => {
          setFetchingData(false);

          setBlockedStatus(data.message == "access_blocked");
          setJobsList(data.jobs);
          //console.log(jobsList);
        }, 1000);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div id="list-jobs-today" className="main-container list-jobs">
      <Header />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        {!fetchingData && jobsList && (
          <>
            <BackButton />
            <h1>
              Tomorrow&apos;s Jobs (
              {FormatDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))}
              )
            </h1>
            <div className="standard-container" id="jobs-list-container">
              <table id="jobs-list-table">
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {jobsList.count < 1 && (
                    <tr style={{ background: "#fff" }}>
                      <td colSpan={2}>
                        You do not have any listed for tomorrow
                      </td>
                    </tr>
                  )}

                  {jobsList.count > 0 && (
                    <>
                      {jobsList.items.map((job) => (
                        <tr key={job.JobId}>
                          <td>{job.customer.CustomerName}</td>
                          <td>{job.JobTime}</td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ListJobsTomorrow;
