import "./Clients.scss";
//import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getApiConfig } from "../../apiConfig";
import Unauthorised from "../notifs/Unauthorised";
import BackButton from "../elements/backbutton/Backbutton";
import DesktopMenu from "../menu/DesktopMenu";
import { CheckAdmin } from "../utils/CheckAdmin";
import { useTranslation } from "react-i18next";
import Header from "../header/Header";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";
const apiConfig = getApiConfig();
const Addclient = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { t } = useTranslation();
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [telephone, setTelephone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submittedError, setSubmittedError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [previousClient, setPreviousClient] = useState(false);
  const [blockedStatus, setBlockedStatus] = useState(false);
  const isAdmin = CheckAdmin();

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");

  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const client = {
      customer_name: name.trim(),
      customer_email: email.trim(),
      customer_mobile: mobile.trim() ? mobile.trim() : telephone.trim(),
      customer_contact: contact.trim(),
      customer_telephone: telephone.trim(),
    };
    setPreviousClient(client.customer_name);

    fetch(api_url + "/customer/add", {
      method: "PUT",
      headers: api_headers,
      body: JSON.stringify(client),
    })
      .then((response) => {
        if (response.ok) {
          setSubmitting(false);
          setSubmitted(true);
          setName("");
          setTelephone("");
          setEmail("");
          setMobile("");
        } else {
          throw new Error(response.status);
        }
      })
      .catch((error) => {
        console.log(error);
        setSubmitting(false);
        setSubmitted(true);
        setTimeout(function () {
          setSubmitting(false);
          setSubmittedError(true);
        }, 500);
      });
  };

  useEffect(() => {
    fetch(api_url + "/user/profile", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        setBlockedStatus(data.message == "access_blocked");
      })
      .catch((error) => console.log("error", error));
  }, []);

  if (!isAdmin) {
    return (
      <>
        <Header />
        <BackButton />
        <div className="standard-container alert">
          <div className="alert alert-warning">{t("notFound")}</div>
        </div>
      </>
    );
  } else {
    return (
      <div id="add-client" className="main-container">
        <Header />
        <BackButton />
        <div className="desktop-menu-container">
          <DesktopMenu />
        </div>
        <div className="main-content-container">
          {blockedStatus && (
            <div id="unauthorised-container" className="alert-container">
              <Unauthorised />
            </div>
          )}
          {submitted && previousClient && !submittedError && (
            <div className="alert alert-success">
              {previousClient} was added to your client list.
            </div>
          )}
          {submitted && submittedError && (
            <div className="alert alert-warning">
              Unable to add to your client list.
            </div>
          )}
          {submitting && (
            <div id="loader-container">
              <div className="loader"></div>
            </div>
          )}
          {!submitting && !blockedStatus && (
            <>
              <h1>Add New Client</h1>
              <div id="form-container">
                <form onSubmit={handleSubmit} id="add-client-form">
                  <div className="form-group">
                    <label htmlFor="customer_name">
                      Customer name<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Client Name"
                      id="customer_name"
                      name="customer_name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="customer_contact">
                      Contact name<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <input
                      onChange={(e) => setContact(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Contact Name"
                      id="customer_contact"
                      name="customer_contact"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="customer_email">
                      Email<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      id="customer_email"
                      name="customer_email"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="customer_mobile">Mobile:</label>
                    <input
                      onChange={(e) => setMobile(e.target.value)}
                      type="tel"
                      className="form-control"
                      placeholder="Enter mobile number"
                      id="customer_mobile"
                      name="customer_mobile"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="customer_telephone">
                      Telephone<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <input
                      onChange={(e) => setTelephone(e.target.value)}
                      type="tel"
                      className="form-control"
                      placeholder="Enter telephone number"
                      id="customer_telephone"
                      name="customer_telephone"
                      required
                    />
                  </div>
                  <button className="btn btn-primary" type="submit">
                    Save
                  </button>
                </form>{" "}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default Addclient;
