import "./Company.scss";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
//import AccessToken from "../auth/GetAccessToken";
import Unauthorised from "../notifs/Unauthorised";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

const Company = () => {
  const [fetchingData, setFetchingData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [companyMobile, setCompanyMobile] = useState(null);
  const [companyEmail, setCompanyEmail] = useState(null);
  const [accessBlocked, setAccessBlocked] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    setFetchingData(true);
    setErrorDisplay(false);
    fetch(api_url + "/user/company", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        console.log(result);
        setAccessBlocked(data.message == "access_blocked");
        //setStaffInfo(data);
        setFetchingData(false);
        console.log(data.message);
        if (data.message == "error") {
          setErrorDisplay(true);
        }
        setCompanyData(data);
        setCompanyName(data.CompanyName);
        setCompanyEmail(data.CompanyEmail);
        setCompanyMobile(data.CompanyMobile.replace("+44", "0"));
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingData(false);
        setErrorDisplay(true);
      });
  }, []);

  return (
    token && (
      <div id="company-details">
        {accessBlocked && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {errorDisplay && (
          <div className="alert alert-warning">
            Unable to locate user details
          </div>
        )}
        {!fetchingData && companyData && (
          <>
            <h1>Your Company Details</h1>
            <div id="companycard">
              <h2>{companyName}</h2>

              <ul>
                <li>{companyMobile}</li>
                <li>{companyEmail}</li>
              </ul>
              <h2>Configuration</h2>
              <ul>
                <li>Employees: 15</li>
                <li>Emailing: on</li>
                <li>SMS: off</li>
              </ul>
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Company;
