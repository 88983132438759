import "./Jobs.scss";
import { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import Unauthorised from "../notifs/Unauthorised";
import DesktopMenu from "../menu/DesktopMenu";
import { ForceLogout } from "../utils/ForceLogout";
//import Elipses from "../helpers/Elipses";
import BackButton from "../elements/backbutton/Backbutton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapLocation,
} from "@fortawesome/free-solid-svg-icons";

import { FormatTime } from "../utils/TimeFormatter";
import Header from "../header/Header";
import ConfirmModal from "../utils/ModalConfirm";
import { FormatDate } from "../utils/DateFormatter";
import ReactGA from "react-ga4";
import { getApiConfig } from "../../apiConfig";
import Cookies from "js-cookie";
const apiConfig = getApiConfig();

library.add(faPhone, faEnvelope, faMapLocation);

const JobDetails = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [jobInfo, setJobInfo] = useState({});
  const [accessBlocked, setAccessBlocked] = useState(null);
  const [fetchingData, setFetchingData] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkedOut, setCheckedOut] = useState(false);
  const [showModalCheckin, setShowModalCheckin] = useState(false);
  const [showModalCheckout, setShowModalCheckout] = useState(false);

  //const roles = ["Admin", "User"];

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const job_id = location.href.split("/").pop();

  const doCheckIn = () => {
    updateCheckIn();
    setShowModalCheckin(false);
    setCheckedIn(true);
  };
  const doCheckOut = () => {
    updateCheckOut();
    setShowModalCheckout(false);
    setCheckedOut(false);
  };

  const handleConfirmCheckin = () => {
    setShowModalCheckin(true);
  };
  const handleConfirmCheckout = () => {
    setShowModalCheckout(true);
  };

  const handleCloseModal = () => {
    setShowModalCheckout(false);
    setShowModalCheckin(false);
  };

  const updateCheckOut = () => {
    let checkout_time = FormatTime();

    let checkoutPayload = {
      job_id: job_id,
      checkout: checkout_time,
    };

    fetch(api_url + "/job/checkout/", {
      headers: api_headers,
      method: "PATCH",
      body: JSON.stringify(checkoutPayload),
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        if (data == 200) {
          jobInfo.Checkout = checkout_time;
          setCheckedOut(true);
          fetchData();
        } else {
          alert("Check-out failed");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const updateCheckIn = () => {
    let checkin_time = FormatTime();

    let checkinPayload = {
      job_id: job_id,
      checkin: checkin_time,
    };

    fetch(api_url + "/job/checkin/", {
      headers: api_headers,
      method: "PATCH",
      body: JSON.stringify(checkinPayload),
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        if (data == 200) {
          jobInfo.Checkin = checkin_time;
          setCheckedIn(true);
          fetchData();
        } else {
          alert("Check-in failed");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const fetchData = () => {
    fetch(api_url + "/view/job/" + job_id, {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        setAccessBlocked(data.message == "access_blocked");
        setJobInfo(data);
        setFetchingData(false);

        //console.log(data.message);
        if (data.message == "error") {
          setErrorDisplay(true);
        }
        //console.log(jobInfo);
        setCustomerName(data.customer.CustomerName);
        setCheckedIn(data.Checkin);
        setCheckedOut(data.Checkout);
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingData(false);
        setErrorDisplay(true);
      });
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60); // Get the number of whole hours
    const remainingMinutes = minutes % 60; // Get the remaining minutes
    let resp = "";
    if (hours === 1) {
      resp += `${hours} hr`;
    }
    if (hours > 1) {
      resp += `${hours} hrs`;
    }
    if (remainingMinutes > 0) {
      resp += ` ${remainingMinutes} min`;
    }
    return resp;
  };

  useEffect(() => {
    setFetchingData(true);
    setErrorDisplay(false);

    fetchData();
  }, []);
  return (
    token && (
      <div className="view-job main-container">
        <Header />
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {accessBlocked && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}
        {errorDisplay && (
          <div className="alert alert-warning">
            Unable to locate job details
          </div>
        )}
        {!fetchingData && !accessBlocked && (
          <div id="list-jobs-today" className="main-container list-jobs">
            <Header />
            <BackButton />
            <div className="desktop-menu-container">
              <DesktopMenu />
            </div>
            <div id="view-job" className="main-content-container">
              {accessBlocked && (
                <div id="unauthorised-container" className="alert-container">
                  <Unauthorised />
                </div>
              )}
              {errorDisplay && (
                <div className="alert alert-warning">
                  Unable to locate job details
                </div>
              )}
              <h1>Job Card{jobInfo.JobDeleted && " - CANCELLED"}</h1>
              {!errorDisplay && (
                <div className="card standard-container" id="jobcard">
                  <div className="card-section">
                    <b>Client:</b> {customerName}
                    <br />
                    <b>Date:</b>{" "}
                    {FormatDate(new Date(new Date(jobInfo.JobDate)))}
                    <br />
                    <b>Time:</b> {jobInfo.JobTime}
                    <br />
                    <b>Duration:</b>{" "}
                    {convertMinutesToHours(jobInfo.JobDuration)}
                  </div>
                  <div className="card-section">
                    <h2>Location:</h2>
                    <div id="address-container">
                      <address>
                        {jobInfo.JobAddress}
                        <br />
                        {jobInfo.JobCity}
                        <br />
                        {jobInfo.JobPostcode}
                      </address>
                    </div>
                    <div id="maps-link-container">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(jobInfo.JobAddress)}+${encodeURIComponent(jobInfo.JobCity)}+${encodeURIComponent(jobInfo.JobPostcode)}`}
                      >
                        <FontAwesomeIcon
                          className="icon"
                          size="3x"
                          icon="map-location"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="card-section">
                    <h2>Comments:</h2>
                    {jobInfo.JobComments}
                  </div>
                  <div className="card-section">
                    {jobInfo.Checkin && (
                      <>
                        Checked-in: {jobInfo.Checkin}
                        <br />
                      </>
                    )}
                    {jobInfo.Checkout && <>Checked-out: {jobInfo.Checkout}</>}

                    <div id="job-buttons-container">
                      {!checkedIn && (
                        <>
                          <button
                            onClick={handleConfirmCheckin}
                            type="button"
                            className="btn btn-primary"
                          >
                            Check-in
                          </button>
                          <ConfirmModal
                            show={showModalCheckin}
                            handleClose={handleCloseModal}
                            handleConfirm={doCheckIn}
                            title="Checking in?"
                            body="Are you sure you want to check-in?"
                          />
                        </>
                      )}
                      {checkedIn && !checkedOut && (
                        <>
                          <button
                            onClick={handleConfirmCheckout}
                            type="button"
                            className="btn btn-secondary"
                          >
                            Check-out
                          </button>
                          <ConfirmModal
                            show={showModalCheckout}
                            handleClose={handleCloseModal}
                            handleConfirm={doCheckOut}
                            title="Checking  out?"
                            body="Are you sure you want to check-out?"
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default JobDetails;
