import "./Clients.scss";
//import AccessToken from "../auth/GetAccessToken";
import { useState, useEffect } from "react";
import Elipses from "../helpers/Elipses";
//import axios from "axios";
import { CheckAdmin } from "../utils/CheckAdmin";
import { ForceLogout } from "../utils/ForceLogout";
import { useTranslation } from "react-i18next";
import Unauthorised from "../notifs/Unauthorised";
import BackButton from "../elements/backbutton/Backbutton";
import DesktopMenu from "../menu/DesktopMenu";
import Header from "../header/Header";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();
//import Unauthorised from "../notifs/Unauthorised";

const Addclient = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const { t } = useTranslation();
  const [fetchingData, setFetchingData] = useState(true);
  const [fileSentError, setFileSentError] = useState(false);
  const [fileSent, setFileSent] = useState(false);
  const [fileSentSuccess, setFileSentSuccess] = useState(null);
  const [sendingFile, setSendingFile] = useState(false);
  const [formTargetData, setFormTargetData] = useState(null);
  const [fileGood, setFileGood] = useState(false);
  const [file, setFile] = useState(null);
  const [blockedStatus, setBlockedStatus] = useState(false);
  const isAdmin = CheckAdmin();

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };

  const doCheckFile = (event) => {
    event.preventDefault;
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
    setFileGood(true);
  };

  const doFileUpload = async (event) => {
    event.preventDefault;
    setSendingFile(true);
    //if await is removed, console log will be called before the uploadFile() is executed completely.
    //since the await is added, this will pause here then console log will be called
    let res = await uploadFile(file);
    console.log(res.status);
    console.log(res.data);
    setSendingFile(false);
    setFileSent(true);
    setFileSentSuccess(res.status === 204);
    setFileSentError(res.status !== 204);
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    //console.log(formTargetData["fields"]);
    formData.append("acl", formTargetData["fields"]["acl"]);
    formData.append("Content-Type", formTargetData["fields"]["Content-Type"]);
    formData.append("key", formTargetData["fields"]["key"]);
    formData.append(
      "X-Amz-Credential",
      formTargetData["fields"]["x-amz-credential"],
    );
    formData.append(
      "X-Amz-Algorithm",
      formTargetData["fields"]["x-amz-algorithm"],
    );
    formData.append(
      "X-Amz-Signature",
      formTargetData["fields"]["x-amz-signature"],
    );
    formData.append("Policy", formTargetData["fields"]["policy"]);
    formData.append("X-Amz-Date", formTargetData["fields"]["x-amz-date"]);
    formData.append(
      "X-Amz-Security-Token",
      formTargetData["fields"]["x-amz-security-token"],
    );
    formData.append("file", file);

    return await fetch(formTargetData.url, {
      method: "POST",
      body: formData, // Send the formData as the body
    });
  };

  useEffect(() => {
    setFetchingData(true);
    api_headers["Cache-Control"] = "max-age=0";
    fetch(api_url + "/url-fetch/customers-upload-url", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        setBlockedStatus(data.message == "access_blocked");
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        //console.log(data);
        setFormTargetData(data);
        //console.log(formTargetData);
        setTimeout(() => {
          setFetchingData(false);
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  if (!isAdmin) {
    return (
      <>
        <Header />
        <BackButton />
        <div className="standard-container alert">
          <div className="alert alert-warning">{t("notFound")}</div>
        </div>
      </>
    );
  } else {
    return (
      <div id="upload-client-container" className="main-container">
        <Header />
        <BackButton />
        <div className="desktop-menu-container">
          <DesktopMenu />
        </div>
        <div className="main-content-container">
          {blockedStatus && (
            <div id="unauthorised-container" className="alert-container">
              <Unauthorised />
            </div>
          )}

          <>
            <div id="form-container">
              <h1>Bulk customers upload</h1>
              <h2>Instructions</h2>
              <ol>
                <li>
                  Download this{" "}
                  <a href="https://cleanerbility-public-resources.s3.eu-west-2.amazonaws.com/cleanerbility_customers_bulk_upload_template.csv">
                    CSV file
                  </a>
                </li>
                <li>Populate it with your clients data</li>
                <li>Upload the csv file using the form below</li>
              </ol>
              {fileSentSuccess && (
                <div className="alert alert-success">
                  File uploaded successfully
                </div>
              )}
              {fileSentError && (
                <div className="alert alert-danger">
                  Apologies. I was unable to upload your file
                </div>
              )}
              <form onSubmit={doFileUpload}>
                {(fetchingData || sendingFile) && !fileSent && <Elipses />}
                {!fetchingData && !fileSent && !sendingFile && (
                  <input onChange={doCheckFile} accept="text/csv" type="file" />
                )}
                {!fetchingData && !fileSent && (
                  <button
                    onClick={doFileUpload}
                    disabled={!fileGood}
                    type="button"
                    className="btn btn-primary"
                  >
                    {t("uploadButtonText")}
                  </button>
                )}
              </form>
            </div>
          </>
        </div>
      </div>
    );
  }
};

export default Addclient;
