import "./Jobs.scss";
import NewJobForm from "./NewJobForm";
import Header from "../header/Header";
import "./Jobs.scss";
import DesktopMenu from "../menu/DesktopMenu";
import BackButton from "../elements/backbutton/Backbutton";
import ReactGA from "react-ga4";

const Addjob = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  return (
    <div id="add-job" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        <NewJobForm />
      </div>
    </div>
  );
};

export default Addjob;
