import { useEffect, useState } from "react";
import Unauthorised from "../notifs/Unauthorised";
import { ForceLogout } from "../utils/ForceLogout";
import Elipses from "../helpers/Elipses";
import BackButton from "../elements/backbutton/Backbutton";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DesktopMenu from "../menu/DesktopMenu";
import {
  faEnvelope,
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import Header from "../header/Header";
import { CheckAdmin } from "../utils/CheckAdmin";
import ReactGA from "react-ga4";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faPhone, faEnvelope, faComment);

const StaffDetails = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [staffInfo, setStaffInfo] = useState({});
  const [blockedStatus, setBlockedStatus] = useState(false);
  const [accessBlocked, setAccessBlocked] = useState(null);
  const [doingBlock, setDoingBlock] = useState(null);
  const [fetchingData, setFetchingData] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [updateErrorDisplay, setUpdateErrorDisplay] = useState(null);
  const [updateRole, setUpdateRole] = useState(null);
  const [updatingRole, setUpdatingRole] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [displayRole, setDisplayRole] = useState(null);
  const isAdmin = CheckAdmin();

  const roles = ["Admin", "User"];

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");

  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const user_id = location.href.split("/").pop();

  //console.log(user_id);

  const toggleRoleForm = () => {
    //alert(updateRole)
    setUpdateRole(updateRole ? false : true);
  };

  const callHandler = () => {
    // Check if staffInfo.phone is a valid string containing only digits and possibly a leading '+' or '0'
    if (
      typeof staffInfo.phone !== "string" ||
      !/^[\d+]+$/.test(staffInfo.phone)
    ) {
      console.error("Invalid phone number format. ");
      return;
    }

    let mobile = staffInfo.phone;

    // Add country code if the number starts with 0
    if (mobile.charAt(0) === "0") {
      mobile = "+44" + mobile.slice(1);
    }

    // Use encodeURIComponent to ensure the phone number is properly escaped
    const telLink = `tel:${encodeURIComponent(mobile)}`;

    // Set the location.href to the sanitized tel link
    window.location.href = telLink;
  };

  const msgHandler = () => {
    // Check if staffInfo.phone is a valid string containing only digits
    if (
      typeof staffInfo.phone !== "string" ||
      !/^[\d+]+$/.test(staffInfo.phone)
    ) {
      console.error("Invalid phone number format.");
      return;
    }

    let mobile = staffInfo.phone;

    // Add country code if the number starts with 0
    if (mobile.charAt(0) === "0") {
      mobile = "+44" + mobile.slice(1);
    }

    // Use encodeURIComponent to ensure the phone number is properly escaped
    const smsLink = `sms:${encodeURIComponent(mobile)}`;

    // Set the location.href to the sanitized sms link
    window.location.href = smsLink;
  };

  const handleRoleChange = (e) => {
    e.preventDefault();
    setUpdateErrorDisplay(false);
    if (userRole === displayRole || userRole == null) {
      toggleRoleForm();
      return;
    }
    setUpdatingRole(true);
    let submittedData = {
      role: userRole.toLowerCase(),
    };

    fetch(api_url + "/manager/update/user/" + user_id, {
      method: "PATCH",
      headers: api_headers,
      body: JSON.stringify(submittedData),
    })
      .then((response) => {
        if (response.ok) {
          setDisplayRole(userRole);
          setTimeout(function () {
            setUpdatingRole(false);
            toggleRoleForm();
          }, 500);
        } else {
          throw new Error(response.status);
        }
      })
      .catch((error) => {
        console.log(error);
        setUpdateErrorDisplay(true);
        setUpdatingRole(false);
        toggleRoleForm();
      });
  };

  const blockHandler = () => {
    setDoingBlock(true);
    let submittedData = {
      set: blockedStatus ? "unblock" : "block",
    };

    fetch(api_url + "/manager/user/blocking/" + user_id, {
      method: "PATCH",
      headers: api_headers,
      body: JSON.stringify(submittedData),
    })
      .then((response) => {
        if (response.ok) {
          //setDoingBlock(false)
          setTimeout(function () {
            setDoingBlock(false);
            setBlockedStatus(blockedStatus ? false : true);
          }, 1000);
          console.log(blockedStatus);
        } else {
          setDoingBlock(false);
          throw new Error(response.status);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setFetchingData(true);
    setErrorDisplay(false);
    setUpdateRole(false);
    setUpdatingRole(false);
    fetch(api_url + "/manager/view/user/" + user_id, {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        setAccessBlocked(data.message == "access_blocked");
        setBlockedStatus(data.enabled == true ? false : true);
        setStaffInfo(data);
        setFetchingData(false);
        setDisplayRole(data.role);

        console.log(data);
        if (data.message == "error") {
          setErrorDisplay(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingData(false);
        setErrorDisplay(true);
      });
  }, []);

  return (
    <div id="view-staff" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {!fetchingData && !accessBlocked && isAdmin && (
          <>
            {fetchingData && (
              <div id="loader-container">
                <div className="loader"></div>
              </div>
            )}
            {accessBlocked && (
              <div id="unauthorised-container" className="alert-container">
                <Unauthorised />
              </div>
            )}
            {errorDisplay && (
              <div className="alert alert-warning">
                Unable to locate user details
              </div>
            )}
            {updateErrorDisplay && (
              <div className="alert alert-warning">
                Unable to update user details
              </div>
            )}
            <h1 className="hide">Staff Details</h1>
            <br />
            <div className="card" id="staffcard">
              {doingBlock && (
                <div className="block-btn-cont">
                  <Elipses />
                </div>
              )}
              {blockedStatus && !doingBlock && (
                <div className="block-btn-cont alert alert-danger">
                  <p>User is blocked</p>
                  <button
                    onClick={() => {
                      blockHandler(false);
                    }}
                    className="btn btn-secondary"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-lock-open" /> Unblock
                  </button>
                </div>
              )}
              {!blockedStatus && !doingBlock && (
                <div className="block-btn-cont">
                  <button
                    onClick={() => {
                      blockHandler(true);
                    }}
                    className="btn btn-info"
                  >
                    <FontAwesomeIcon icon="fa-solid fa-lock" /> Block
                  </button>
                </div>
              )}
              <img
                className="card-img-top"
                src="/profile-image-placeholder.jpeg"
                alt={staffInfo.name}
              />
              <div className="card-body">
                <h4 className="card-title">{staffInfo.name}</h4>
                <ul>
                  <li>
                    {updatingRole && (
                      <div className="block-btn-cont updating-role">
                        <Elipses />
                      </div>
                    )}
                    {!updateRole && !updatingRole && (
                      <>
                        <b>Role:</b>{" "}
                        <span className="label-role">{displayRole} </span>
                        <button
                          onClick={toggleRoleForm}
                          className="btn btn-info"
                        >
                          Edit
                        </button>
                      </>
                    )}
                    {updateRole && !updatingRole && (
                      <form
                        onSubmit={(e) => handleRoleChange(e)}
                        id="userrole-form"
                        className="form-inline"
                      >
                        <div className="form-group">
                          <select
                            onChange={(e) => setUserRole(e.target.value)}
                            id="select-userrole"
                            className="form-select"
                          >
                            {roles.map((role) => (
                              <option
                                selected={
                                  displayRole.toLowerCase() ==
                                  role.toLowerCase()
                                }
                                key={role}
                              >
                                {role}
                              </option>
                            ))}
                          </select>
                          <button type="submit" className="btn btn-info">
                            Save
                          </button>
                          <button
                            onClick={toggleRoleForm}
                            className="btn btn-info"
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    )}
                  </li>
                  <li>
                    <b>Email:</b> {staffInfo.email}{" "}
                  </li>
                  <li id="contact-links">
                    <button
                      className="btn btn-dark"
                      type="button"
                      onClick={() => {
                        callHandler();
                      }}
                    >
                      <FontAwesomeIcon icon="phone" />
                      {"  "}
                      Call
                    </button>

                    <button
                      className="btn btn-dark"
                      type="button"
                      onClick={() => {
                        msgHandler();
                      }}
                    >
                      <FontAwesomeIcon icon="comment" /> Message
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StaffDetails;
