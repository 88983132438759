import { useState } from "react";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserDetails from "../utils/GetUserDetails";
import UserPool from "../utils/UserPool";
import { useTranslation } from "react-i18next";
import logo from "../../images/cleanerbility-logo.png";
import { ValidateEmail } from "../utils/ValidateEmail";
import "./auth.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";

library.add(fab, faEyeSlash);

const Signin = () => {
  const [username, setUsername] = useState("");
  //const [email, setEmail] = useState('');
  const [password, setPassword] = useState("");
  const [failedLogin, setFailedLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPasswordVerify, setNewPasswordVerify] = useState(null);
  const [passwordChangedSuccess, setPasswordChangedSuccess] = useState(false);
  const [passwordChangedError, setPasswordChangedError] = useState(false);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  ReactGA.send({ hitType: "pageview", page: window.location.pathname });

  const toggleShowPassword = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "User",
      action: `toggle visibility of password - ${showPassword ? "hide" : "show"}`,
    });
    setShowPassword(showPassword ? false : true);
    return false;
  };

  const handleLinkClick = () => {
    ReactGA.event({
      category: "Link",
      action: "Click",
      label: "Pricing Signup",
    });
  };

  const newPasswordSubmit = (e) => {
    e.preventDefault();
    passwordNotifsReset();

    if (newPassword !== newPasswordVerify) {
      alert("Passwords do not match");
      return false;
    }

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    user.confirmPassword(verificationCode, newPassword, {
      onSuccess() {
        ReactGA.event({
          category: "User",
          action: "new password confirmed",
        });
        console.log("Password confirmed!");
        setShowNewPasswordForm(false);
        setPasswordChangedSuccess(true);
        setVerificationCode(null);
        setVerificationCode(null);
        setNewPassword(null);
        setNewPasswordVerify(null);
      },
      onFailure(err) {
        ReactGA.event({
          category: "User",
          action: "new password not confirmed",
        });
        console.log(err);
        console.log("Password not confirmed!");
        setVerificationCode(null);
        setNewPassword(null);
        setNewPasswordVerify(null);
        setPasswordChangedError(false);
      },
    });
  };

  const onForgotPassword = () => {
    //setForcePasswordChange(false)
    passwordNotifsReset();
    if (!ValidateEmail(username.trim())) {
      alert(t("enterValidEmailText"));
      return false;
    }

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        ReactGA.event({
          category: "User",
          action: "forgot password process initiated",
        });
        console.log("CodeDeliveryData from forgotPassword: " + data);
        setShowNewPasswordForm(true);
      },
      onFailure: function (err) {
        ReactGA.event({
          category: "User",
          action: "forgot password fail",
        });
        alert(err.message || JSON.stringify(err));
      },
      //Optional automatic callback
    });
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    passwordNotifsReset();

    setLoading(true);

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    let sessionUserAttributes;
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        ReactGA.event({
          category: "User",
          action: "login success",
        });
        Cookies.set("token", result.getIdToken().getJwtToken(), {
          expires: 1,
          secure: true,
        });

        UserDetails(result.getIdToken().getJwtToken());
        setTimeout(function () {
          //setLoading(false);
          window.location.reload();
        }, 2500);
      },
      onFailure: (err) => {
        console.log("login failure: ", err);
        setFailedLogin(true);
        setLoading(false);
        if (err == "NotAuthorizedException: User is disabled.") {
          ReactGA.event({
            category: "User",
            action: "login failure - user blocked",
          });
          setErrorMessage(t("accessBlocked"));
        }
        if (err == "NotAuthorizedException: Incorrect username or password.") {
          ReactGA.event({
            category: "User",
            action: "login failure - incorrect credentials",
          });
          setErrorMessage(t("incorrectUsernamePassword"));
        }
        if (err == "new password required") {
          alert("Please force new password");
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log(requiredAttributes);
        setLoading(false);

        sessionUserAttributes = userAttributes;
        console.log(sessionUserAttributes);

        if (newPassword) {
          // these properties are immutable
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          delete userAttributes.phone_number;
          delete userAttributes.email;

          user.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: (result) => {
              ReactGA.event({
                category: "User",
                action: "new password set successfully",
              });
              console.log("New password set successfully:", result);
              setPassword("");
              setPasswordChangedSuccess(true);
              setForcePasswordChange(false);
            },
            onFailure: (err) => {
              ReactGA.event({
                category: "User",
                action: "new password set failure",
              });
              console.error("Failed to set new password:", err);
              alert("Failed to change password: " + err.message);
            },
          });
        } else {
          setForcePasswordChange(true);
        }
      },
    });
  };

  const passwordNotifsReset = () => {
    setPasswordChangedSuccess(false);
    setPasswordChangedError(false);
    setFailedLogin(false);
    setErrorMessage(null);
  };

  return (
    <div id="login-container" className={loading ? "loading" : null}>
      {!loading && (
        <div id="login-logo-container">
          <img src={logo} alt="Cleanerbility Logo" />
        </div>
      )}
      <div id="response-container">
        {failedLogin && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        {passwordChangedSuccess && (
          <div className="alert alert-success">
            {t("passwordChangedSuccessfully")}
          </div>
        )}
        {passwordChangedError && (
          <div className="alert alert-warning">{t("passwordChangeFailed")}</div>
        )}
        {showNewPasswordForm && (
          <div className="alert alert-warning">
            {t("passwordChangeCodeSent")}
          </div>
        )}
        {forcePasswordChange && (
          <div className="alert alert-info">
            {t("firstTimeLoginMessage")}
            <br />
            {t("changePasswordMessage")}
          </div>
        )}
      </div>
      {loading && (
        <div id="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {forcePasswordChange && (
        <>
          <form onSubmit={onSubmitLogin}>
            <div className="form-group">
              <label htmlFor="inputPass">{t("newPasswordText")}</label>
              <input
                className="form-control"
                id="inputPass"
                required
                type="password"
                placeholder={t("newPasswordText")}
                //value={newPassword}
                defaultValue={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              {t("saveButtonLabel")}
            </button>
          </form>
        </>
      )}

      {showNewPasswordForm && (
        <form id="new-password-form" onSubmit={newPasswordSubmit}>
          <div className="form-group">
            <label className="sr-only" htmlFor="inputCode">
              Code
            </label>
            <input
              className="form-control"
              id="inputCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
              type="number"
              placeholder={t("verificationCodeText")}
            />
          </div>
          <div className="form-group">
            <label className="sr-only" htmlFor="inputPass1">
              New password
            </label>
            <input
              className="form-control"
              id="inputPass1"
              required
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="sr-only" htmlFor="inputPass1">
              Repeat password
            </label>
            <input
              className="form-control"
              id="inputPass2"
              required
              type="password"
              placeholder="Repeat password"
              value={newPasswordVerify}
              onChange={(e) => setNewPasswordVerify(e.target.value)}
            />
          </div>
          <div id="auth-links">
            <button className="btn btn-primary" type="submit">
              Set Password
            </button>
            <button
              className="btn btn-default"
              onClick={() => setShowNewPasswordForm(false)}
            >
              Back
            </button>
          </div>
        </form>
      )}

      {!showNewPasswordForm && !loading && !forcePasswordChange && (
        <form onSubmit={onSubmitLogin}>
          <div className="form-group">
            <label className="sr-only" htmlFor="inputEmail">
              Email
            </label>

            <input
              className="form-control"
              id="inputEmail"
              required
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t("signinFormEmailFieldPlaceholder")}
            />
          </div>
          <div className="form-group password-field-container">
            <label className="sr-only" htmlFor="inputPassword">
              Password
            </label>

            <input
              id="inputPassword"
              className="form-control"
              type={showPassword ? "text" : "password"}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("signinFormPasswordFieldPlaceholder")}
            />
            <span className="show-password-icon" onClick={toggleShowPassword}>
              <FontAwesomeIcon icon={faEyeSlash} />
            </span>
          </div>
          <button className="btn btn-primary" type="submit">
            {t("signinFormLoginButtonLabel")}
          </button>
          <div id="auth-links">
            <ul>
              <li>
                <a
                  href="#"
                  onClick={() => onForgotPassword()}
                  className="btn btn-default"
                >
                  {t("forgotPasswordLinkText")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.cleanerbility.com/pricing"
                  rel="noreferrer"
                  target="_blank"
                  className="btn btn-default"
                  onClick={handleLinkClick}
                >
                  {t("signupLinkText")}
                </a>
              </li>
            </ul>
          </div>
        </form>
      )}
    </div>
  );
};

export default Signin;
