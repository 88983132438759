import "./Jobs.scss";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { ForceLogout } from "../utils/ForceLogout";
import Cookies from "js-cookie";
import { getApiConfig } from "../../apiConfig";
import ReactGA from "react-ga4";
const apiConfig = getApiConfig();

const NewJobForm = () => {
  const [clients, setClients] = useState("");
  const [staffMembers, setStaffMembers] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCleaner1, setSelectedCleaner1] = useState(null);
  const [selectedCleaner2, setSelectedCleaner2] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [durationHrs, setDurationHrs] = useState("0");
  const [durationMins, setDurationMins] = useState("0");
  const [recurrence, setRecurrence] = useState("once");
  const [comments, setComments] = useState("");
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [postcode, setPostcode] = useState(null);
  const [sendingData, setSendingData] = useState(false);
  const [sentData, setSentData] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [showForm, setShowForm] = useState(false);

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;

  const token = Cookies.get("token");
  let api_headers = {
    Authorization: "Bearer " + token,
    "x-api-key": api_key,
  };

  const getCustomers = async () => {
    fetch(api_url + "/customers", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);

        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        let count = Object.keys(data.customers.items).length;
        let clientsData = [];
        if (count > 0) {
          for (var i = 0; i < count; i++) {
            clientsData.push({
              value: data.customers.items[i].CustomerId,
              label: data.customers.items[i].CustomerName,
            }); // Create your array of data
          }
        }

        setClients(clientsData);
      });
  };

  const getCleaners = async () => {
    fetch(api_url + "/manager/list/users", {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);

        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }

        let count = Object.keys(data.users).length;
        let staffData = [];
        if (count > 0) {
          for (var i = 0; i < count; i++) {
            staffData.push({
              value: data.users[i].UserId,
              label: data.users[i].UserName,
            });
          }
        }

        setStaffMembers(staffData);
      });
  };

  const today = new Date().toISOString().split("T")[0];
  useEffect(() => {
    getCustomers();
    getCleaners();
    setTimeout(() => {
      setShowForm(true);
    }, 1500);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSentData(false);

    let jobClient;
    let jobDate;
    let jobStaff;
    let jobAddress;
    let jobCity;
    let jobPostcode;
    let jobTime;
    let duration;

    if (durationHrs == 0 && durationMins == 0) {
      alert("Please set the duration");
      return false;
    }

    duration = parseInt(durationHrs) * 60 + parseInt(durationMins);

    if (
      !selectedClient ||
      selectedClient.trim() == "" ||
      selectedClient == null
    ) {
      alert("Please select the client");
      return false;
    } else {
      jobClient = selectedClient;
    }

    if (
      !selectedCleaner1 ||
      selectedCleaner1.trim() == "" ||
      selectedCleaner1 == null
    ) {
      alert("Please select a cleaner");
      return false;
    } else {
      jobStaff = selectedCleaner1;
    }

    if (selectedDate === null) {
      alert("Please select a date");
      return;
    } else {
      jobDate = selectedDate;
    }

    if (!selectedTime || selectedTime == "" || selectedTime == null) {
      alert("Please select the Time");
      return false;
    } else {
      jobTime = selectedTime;
    }

    if (!address || address.trim() == "" || address == null) {
      alert("Please enter the address");
      return false;
    } else {
      jobAddress = address.trim();
    }

    if (!city || city.trim() == "" || city == null) {
      alert("Please enter the town or city");
      return false;
    } else {
      jobCity = city.trim();
    }

    if (postcode && postcode.trim() !== "" && postcode !== null) {
      jobPostcode = postcode.trim();
    }

    if (selectedCleaner2) {
      jobStaff += "," + selectedCleaner2;
    }

    let payload = {
      customer: jobClient,
      date: jobDate,
      time: jobTime,
      assignees: jobStaff,
      address: jobAddress,
      city: jobCity,
      comments: comments,
      duration: duration,
    };

    if (jobPostcode) {
      payload["postcode"] = jobPostcode;
    }

    if (
      recurrence === "weekly" ||
      recurrence === "bi-weekly" ||
      recurrence === "monthly"
    ) {
      payload["repeats"] = recurrence;
    }

    setSendingData(true);

    sendNewJob(payload);
    setTimeout(() => {
      setSendingData(false);
      setSentData(true);
      if (apiSuccess) {
        clearForm();
      }
    }, 2000);
  };

  const handleClientChange = (selectedOption) => {
    setSelectedClient(selectedOption.value);
  };

  const handleCleanerChange1 = (selectedOption) => {
    setSelectedCleaner1(selectedOption.value);
  };

  const handleCleanerChange2 = (selectedOption) => {
    setSelectedCleaner2(selectedOption.value);
  };

  const clearForm = () => {
    setSelectedClient(null);
    setSelectedCleaner1(null);
    setSelectedCleaner2(null);
    setSelectedDate(null);
    setSelectedTime(null);
    setAddress(null);
    setCity(null);
    setPostcode(null);
    setComments(null);
    setDurationHrs("1");
    setDurationMins("0");
    setRecurrence("once");
  };

  const sendNewJob = async (payload) => {
    fetch(api_url + "/manager/job/create", {
      headers: api_headers,
      method: "PUT",
      body: JSON.stringify(payload),
    })
      .then((response) => response.text())
      .then((result) => {
        ReactGA.event({
          category: "User",
          action: "Created a job",
        });
        const data = JSON.parse(result);
        setApiSuccess(data.message === "ok");
        setApiError(data.message === "error");
        setSentData(true);
        setSendingData(false);
      })
      .catch((error) => {
        ReactGA.event({
          category: "User",
          action: "Failed to create a job",
          label: error,
        });
        console.log("error", error);
        setApiError(true);
      });
  };

  return (
    <div>
      {(sendingData || !showForm) && (
        <div id="loader-container">
          <div className="loader"></div>
        </div>
      )}

      {!sendingData && showForm && (
        <form id="new-job-form" onSubmit={handleSubmit}>
          {apiSuccess && (
            <div className="alert alert-success" role="alert">
              Job added successfully
            </div>
          )}
          {apiError && sentData && apiError && (
            <div className="alert alert-warning" role="alert">
              Unable to add job
            </div>
          )}
          <h1>Add new job</h1>
          <div className="form-group">
            <label htmlFor="client-select">
              Select client<span>*</span>
            </label>
            <Select
              required
              aria-labelledby="Select client"
              ariaLiveMessages={{}}
              label="Select client"
              inputId="client-select"
              name="client"
              options={clients}
              defaultValue={selectedClient}
              onChange={handleClientChange}
            />
          </div>
          <div id="cleaners-group">
            <div className="cleaner-select-container">
              <label>
                Cleaner 1<span>*</span>
              </label>
              <Select
                required
                aria-labelledby="Select cleaner"
                ariaLiveMessages={{}}
                inputId="cleaner-select1"
                name="cleaner1"
                options={staffMembers}
                defaultValue={selectedCleaner1}
                onChange={handleCleanerChange1}
              />
            </div>
            <div className="cleaner-select-container">
              <label>Cleaner 2</label>
              <Select
                aria-labelledby="Select cleaner"
                ariaLiveMessages={{}}
                inputId="cleaner-select2"
                name="cleaner2"
                options={staffMembers}
                defaultValue={selectedCleaner2}
                onChange={handleCleanerChange2}
              />
            </div>
          </div>
          <div id="time-date-group">
            <div className="form-group" id="job-date-input-container">
              <label htmlFor="job-date-input">
                Date<span>*</span>
              </label>
              <input
                required
                min={today}
                className="form-control"
                type="date"
                placeholder="Date"
                id="job-date-input"
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div className="form-group" id="job-time-input-container">
              <label htmlFor="job-time-input">
                Time<span>*</span>
              </label>
              <input
                required
                id="job-time-input"
                className="form-control"
                type="time"
                placeholder="time"
                onChange={(e) => setSelectedTime(e.target.value)}
                step="900"
              />
            </div>
            <div className="form-group" id="duration-group">
              <label htmlFor="jobDuration">
                Duration<span>*</span>
              </label>
              <br />
              <select
                value={durationHrs}
                onChange={(e) => setDurationHrs(e.target.value)}
                className="form-control"
              >
                <option value="0">0 hr</option>
                <option value="1">1 hr</option>
                <option value="2">2 hr</option>
                <option value="3">3 hr</option>
                <option value="4">4 hr</option>
                <option value="5">5 hr</option>
                <option value="6">6 hr</option>
                <option value="7">7 hr</option>
                <option value="8">8 hr</option>
              </select>
              <select
                value={durationMins}
                onChange={(e) => setDurationMins(e.target.value)}
                className="form-control"
              >
                <option>00m</option>
                <option value="15">15m</option>
                <option value="30">30m</option>
                <option value="45">45m</option>
              </select>
            </div>
          </div>
          <div id="recurrence-group">
            <label>Frequency</label>
            <select
              value={recurrence}
              onChange={(e) => setRecurrence(e.target.value)}
              className="form-control"
            >
              <option>Once</option>
              <option value="weekly">Weekly</option>
              <option value="bi-weekly">Fortnightly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <div id="form-location-group">
            <div className="form-group">
              <label htmlFor="address">
                Location<span>*</span>
              </label>
              <input
                required
                className="form-control"
                type="text"
                placeholder="Address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div id="location-group">
              <div className="form-group">
                <label className="sr-only" htmlFor="jobCity">
                  City
                </label>
                <input
                  required
                  className="form-control"
                  type="text"
                  placeholder="City"
                  id="jobCity"
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only" htmlFor="jobPostcode">
                  Postcode
                </label>
                <input
                  id="jobPostcode"
                  className="form-control"
                  type="text"
                  placeholder="Postcode"
                  onChange={(e) => setPostcode(e.target.value)}
                />
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="form-group">
            <textarea
              className="form-control"
              type="text"
              placeholder="Instructions"
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
          <Button type="submit">Save</Button>{" "}
        </form>
      )}
    </div>
  );
};

export default NewJobForm;
