import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import Cookies from "js-cookie";
import BackButton from "../elements/backbutton/Backbutton";
//import Elipses from "../helpers/Elipses";
import Unauthorised from "../notifs/Unauthorised";
import ConfirmModal from "../utils/ModalConfirm";
import Header from "../header/Header";
import { ForceLogout } from "../utils/ForceLogout";
import { CheckAdmin } from "../utils/CheckAdmin";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { ValidateEmail } from "../utils/ValidateEmail";
import DesktopMenu from "../menu/DesktopMenu";
import ReactGA from "react-ga4";
import { getApiConfig } from "../../apiConfig";
const apiConfig = getApiConfig();

library.add(faTrash, faPenToSquare);

const ClientDetails = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  const [blockedStatus, setBlockedStatus] = useState(null);
  const [fetchingData, setFetchingData] = useState(null);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [updateErrorDisplay, setUpdateErrorDisplay] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [clientMobile, setClientMobile] = useState(null);
  const [clientName, setClientName] = useState(null);
  const [clientTelephone, setClientTelephone] = useState(null);
  const [clientEmail, setClientEmail] = useState(null);
  const [clientContact, setClientContact] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const isAdmin = CheckAdmin();
  const navigate = useNavigate();

  let api_url = apiConfig.apiUrl;
  let api_key = apiConfig.apiKey;
  const token = Cookies.get("token");
  let api_headers = {
    "x-api-key": api_key,
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  const customer_id = location.href.split("/").pop();

  const callHandler = (mobile) => {
    if (mobile.charAt(0) == "0") {
      mobile = "+44" + mobile.slice(1);
    }
    window.location.href = `tel://${mobile}`;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const RemoveClient = () => {
    //console.log("delete client: " + clientId);
    setUpdateErrorDisplay(false);
    setShowModal(false);
    fetch(api_url + `/customer/${clientId}/update/`, {
      method: "PATCH",
      headers: api_headers,
      body: JSON.stringify({ customerId: clientId, delete: true }),
    })
      .then((response) => {
        console.log(response);
        setFetchingData(true);
        if (response.ok) {
          response.json().then(() => {
            setTimeout(() => {
              setFetchingData(false);
              setEditing(false);
              navigate(-1);
            }, 500);
          });
        } else if (response.status === 403) {
          setBlockedStatus(response.message == "access_blocked");
        } else {
          throw new Error(response.status);
        }
      })

      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          console.log("error");
          setFetchingData(false);
          setEditing(false);
          setUpdateErrorDisplay(true);
        }, 500);
      });
  };

  const msgHandler = () => {
    if (ValidateEmail(clientEmail.toLowerCase())) {
      window.location.href = `mailto:${clientEmail}`;
    } else {
      alert(
        "Unable to send email. Invalid email address " +
          clientEmail.toLowerCase(),
      );
    }
  };

  useEffect(() => {
    setFetchingData(true);
    setErrorDisplay(false);
    setUpdateErrorDisplay(false);
    setBlockedStatus(false);
    fetch(api_url + "/customer/" + customer_id, {
      headers: api_headers,
      method: "GET",
    })
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        if (data.message == "The incoming token has expired") {
          ForceLogout();
        }
        if (data.message == "not_found") {
          setErrorDisplay(true);
        }
        setClientId(data.CustomerId);
        setClientName(data.CustomerName);
        setClientMobile(data.CustomerMobile);

        setClientEmail(data.CustomerEmail);
        setClientContact(data.CustomerContact);
        setFetchingData(false);
        console.log(data.CustomerMobile);
        if (data.CustomerMobile) {
          setClientMobile(data.CustomerMobile);
        }
        if (data.CustomerTel) {
          setClientTelephone(data.CustomerTel);
        }

        if (data.message == "error") {
          setErrorDisplay(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setFetchingData(false);
        setErrorDisplay(true);
      });
  }, []);

  const updateDetails = (e) => {
    e.preventDefault();
    setUpdateErrorDisplay(false);
    if (!ValidateEmail(clientEmail)) {
      alert("Invalid email address");
      return;
    }

    if (clientTelephone == "" && clientMobile == "") {
      alert("Please enter a telephone or mobile number");
      return;
    }

    if (clientName == "") {
      alert("Please enter a namer for the client");
      return;
    }

    let submittedData = {
      customerId: clientId,
      name: clientName,
      contact: clientContact,
      email: clientEmail,
      phone: clientTelephone,
      mobile: clientMobile,
    };

    fetch(api_url + `/customer/${clientId}/update/`, {
      method: "PATCH",
      headers: api_headers,
      body: JSON.stringify(submittedData),
    })
      .then((response) => {
        console.log(response);
        setFetchingData(true);
        if (response.ok) {
          response.json().then(() => {
            setTimeout(() => {
              setFetchingData(false);
              setEditing(false);
            }, 500);
          });
        } else if (response.status === 403) {
          setBlockedStatus(response.message == "access_blocked");
        } else {
          throw new Error(response.status);
        }
      })

      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          //console.log("error");
          setUpdateErrorDisplay(true);
        }, 500);
      });
  };

  return (
    <div id="view-client" className="main-container">
      <Header />
      <BackButton />
      <div className="desktop-menu-container">
        <DesktopMenu />
      </div>
      <div className="main-content-container">
        {fetchingData && (
          <div id="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {errorDisplay && (
          <div className="alert alert-warning">
            Unable to locate client details
          </div>
        )}
        {updateErrorDisplay && (
          <div className="alert alert-warning">
            Unable to update client details
          </div>
        )}
        {blockedStatus && (
          <div id="unauthorised-container" className="alert-container">
            <Unauthorised />
          </div>
        )}

        {!fetchingData && isAdmin && !errorDisplay && (
          <>
            <h1 className="sr-only">Client Details</h1>
            <div className="card standard-container" id="clientcard">
              <div className="card-body">
                {editing && (
                  <form onSubmit={updateDetails}>
                    <div className="form-group">
                      <label>Client name</label>
                      <input
                        id="name-input"
                        className="form-control"
                        name="name"
                        required
                        placeholder="name please"
                        defaultValue={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                      />{" "}
                    </div>
                    <div className="form-group">
                      <label>Contact name</label>
                      <input
                        id="name-input"
                        className="form-control"
                        name="contact"
                        required
                        placeholder="name of contact"
                        defaultValue={clientContact}
                        onChange={(e) => setClientContact(e.target.value)}
                      />{" "}
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        id="email-input"
                        type="email"
                        className="form-control"
                        name="email"
                        required
                        placeholder="email of contact"
                        defaultValue={clientEmail}
                        onChange={(e) => setClientEmail(e.target.value)}
                      />{" "}
                    </div>
                    <div className="form-group">
                      <label>Landline</label>
                      <input
                        id="telf-input"
                        type="tel"
                        className="form-control"
                        name="landline"
                        placeholder="email of contact"
                        defaultValue={clientTelephone ? clientTelephone : ""}
                        onChange={(e) => setClientTelephone(e.target.value)}
                      />{" "}
                    </div>
                    <div className="form-group">
                      <label>Mobile</label>
                      <input
                        id="mobile-input"
                        type="tel"
                        className="form-control"
                        name="mobile"
                        placeholder="mobile of contact"
                        defaultValue={clientMobile ? clientMobile : ""}
                        onChange={(e) => setClientMobile(e.target.value)}
                      />{" "}
                    </div>
                    <button
                      className="btn btn-default"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </form>
                )}

                {!editing && isAdmin && (
                  <>
                    <p>Client</p>
                    <h4 className="card-title">{clientName} &nbsp;</h4>
                    <ul>
                      <li>Contact: {clientContact} </li>
                      <li>Email: {clientEmail} </li>
                    </ul>
                    <ul id="client-contacts">
                      {clientMobile && (
                        <li>
                          <Link
                            className="btn btn-dark"
                            to="#"
                            onClick={() => {
                              callHandler(clientMobile);
                            }}
                          >
                            <FontAwesomeIcon icon="mobile" />
                            <span>Call&nbsp;</span>Mobile
                          </Link>
                        </li>
                      )}
                      {clientTelephone && (
                        <li>
                          <Link
                            className="btn btn-dark"
                            to="#"
                            onClick={() => {
                              callHandler(clientTelephone);
                            }}
                          >
                            <FontAwesomeIcon icon="phone" />
                            <span>Call&nbsp;</span>Landline
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          className="btn btn-dark"
                          to="#"
                          onClick={() => {
                            msgHandler(clientName);
                          }}
                        >
                          <FontAwesomeIcon icon="envelope" />
                          Email
                        </Link>
                      </li>
                    </ul>
                    <ul id="actions">
                      <li>
                        <Link
                          className="btn btn-primary"
                          to="#"
                          onClick={() => {
                            setEditing(true);
                          }}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                          <span className="sr-only">Edit</span>
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="btn btn-light"
                          to="#"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          <FontAwesomeIcon className="icon red" icon="trash" />
                          <span className="sr-only">Remove</span>
                        </Link>
                      </li>
                    </ul>
                  </>
                )}
              </div>
            </div>
            <ConfirmModal
              show={showModal}
              handleClose={handleCloseModal}
              handleConfirm={RemoveClient}
              title="Delete? Are you sure?"
              body="Do you really want to permanently delete? (this cannot be undone)"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ClientDetails;
