import "./Header.scss";
import icon from "../../images/cleanerbility-icon-trans.png";
import SlideUpMenu from "./SlideUpMenu";
import { ForceLogout } from "../utils/ForceLogout";
import { useEffect } from "react";

const Header = () => {
  const user = sessionStorage.getItem("cb-user");
  const headerClick = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    if (!user) {
      ForceLogout();
    }
  });

  return (
    <div id="header-container">
      <div id="icon-container-mobile">
        <img onClick={headerClick} src={icon} alt="Cleanerbility Logo" />
      </div>
      <SlideUpMenu />
    </div>
  );
};

export default Header;
